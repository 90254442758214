import { useState } from "react";
import styles from "./Login.module.css";
import Loading from "../../components/Shared/Loading";
import FormularioLogin from "../../components/Login/FormularioLogin";

export default function Login() {
  const [loading, setLoading] = useState(false);

  return (
    <main>
      <section className={styles.container}>
        {loading ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : (
          <>
            <FormularioLogin setLoading={(e) => setLoading(e)} />
          </>
        )}
      </section>
    </main>
  );
}
