import { useEffect, useState } from "react";
import styles from "./PainelControle.module.css";
import Navbar from "../../components/Shared/Navbar";
import { useUsuario } from "../../context/UsuarioContext";
import SideBarPainel from "../../components/PainelControle/SideBarPainel";
import GerenciarUsuarios from "../../components/PainelControle/GerenciarUsuarios";
import Relatorios from "../../components/PainelControle/Relatorios";
import Atendimentos from "../../components/PainelControle/Atendimentos";
import Monitoramento from "../../components/PainelControle/Monitoramento";
import PreRotas from "../../components/PainelControle/PreRotas";

export default function PainelControle() {
  const [opcaoSelecionada, setOpcaoSelecionada] = useState("Atendimentos");
  const { usuario, consultaUsuario } = useUsuario();
  const token = sessionStorage.getItem("token");
  const opcoes = [
    "Atendimentos",
    "Monitoramento",
    "Pré Rota",
    "Relatórios",
    "Usuarios",
  ];

  useEffect(() => {
    if (!usuario && token) {
      const usuarioId = sessionStorage.getItem("usuarioId");
      if (usuarioId) {
        consultaUsuario(usuarioId);
      }
    }
  }, [token]);

  return (
    <>
      <Navbar />
      <div className={styles.container}>
        <div className={styles.sidebar}>
          <SideBarPainel
            opcoes={opcoes}
            setOpcaoSelecionada={setOpcaoSelecionada}
            opcaoSelecionada={opcaoSelecionada}
          />
        </div>
        <div className={styles.conteudo}>
          {opcaoSelecionada === "Atendimentos" && <Atendimentos />}
          {opcaoSelecionada === "Monitoramento" && <Monitoramento />}
          {opcaoSelecionada === "Pré Rota" && <PreRotas />}
          {opcaoSelecionada === "Relatórios" && <Relatorios />}
          {opcaoSelecionada === "Usuarios" && <GerenciarUsuarios />}
        </div>
      </div>
    </>
  );
}
