import { useState } from "react";
import styles from "./RelatorioDisparoMensagens.module.css";
import Loading from "../../../Shared/Loading";
import CampoInput from "../../../Shared/CampoInput";
import Botao from "../../../Shared/Botao";
import moment from "moment";
import api from "../../../../utils/api";
import FileSaver from "file-saver";

export default function RelatorioDisparoMensagens() {
  const [loading, setLoading] = useState(false);
  const [dataInicio, setDataInicio] = useState<string>(
    moment().subtract(1, "days").format("yyyy-MM-DD")
  );
  const [dataFinal, setDataFinal] = useState<string>(
    moment().format("yyyy-MM-DD")
  );

  const buscaRelatorio = async () => {
    setLoading(true);
    try {
      const resposta = await api.post(
        "relatorio/disparomensagens",
        {
          dataInicio: moment(dataInicio, "yyyy-MM-DD").format("DD/MM/yyyy"),
          dataFinal: moment(dataFinal, "yyyy-MM-DD").format("DD/MM/yyyy"),
        },
        {
          responseType: "blob",
        }
      );

      const dados = resposta.data;
      if (dados) {
        const blob = new Blob([resposta.data], { type: "application/xlsx" });
        FileSaver.saveAs(blob, `Mensagens_Enviadas.xlsx`);
      }
    } catch (error) {
      console.log(error);
      alert("Erro na API");
    }
    setLoading(false);
  };

  return (
    <div className={styles.card}>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.conteudo} onSubmit={buscaRelatorio}>
          <h3>Disparo de Mensagens</h3>
          <CampoInput
            required={true}
            type="date"
            value={dataInicio}
            aoAlterado={(e) => setDataInicio(e)}
          >
            Inicio
          </CampoInput>
          <CampoInput
            required={true}
            type="date"
            value={dataFinal}
            aoAlterado={(e) => setDataFinal(e)}
          >
            Final:
          </CampoInput>
          <Botao cor="primaria" onClick={buscaRelatorio}>
            Gerar
          </Botao>
        </div>
      )}
    </div>
  );
}
