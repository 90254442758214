import React, { KeyboardEvent, useEffect, useState } from "react";
import styles from "./MensagemPronta.module.css";
import { useUsuario } from "../../../../context/UsuarioContext";
import { useProtocolo } from "../../../../context/ProtocoloContext";
import api from "../../../../utils/api";
import { MensagemTemplate } from "../../../../interfaces/IMensagemTemplate";

interface MensagemTemplateProps {
  setMensagem: React.Dispatch<React.SetStateAction<string>>;
  abreAbaTemplate: () => void;
  darFocoAoTextarea: () => void;
}

export default function MensagemPronta({
  setMensagem,
  abreAbaTemplate,
  darFocoAoTextarea,
}: MensagemTemplateProps) {
  const [mensagemTemplate, setMensagemTemplate] = useState<string>(
    "Selecione uma Mensagem"
  );
  const [templates, setTemplates] = useState<MensagemTemplate[]>([]);
  const { usuario } = useUsuario();
  const { protocoloSelecionado } = useProtocolo();

  useEffect(() => {
    const buscaTemplates = async () => {
      try {
        const resposta = await api.get("/mensagemtemplate");
        const todosTemplates: MensagemTemplate[] = resposta.data;
        const chatRegex = /chat-(.*)$/;
        const templates = [];

        for (const template of todosTemplates) {
          const match = chatRegex.exec(template.modelo);
          if (match) {
            templates.push(template);
          }
        }

        setTemplates(templates);
      } catch (erro) {
        console.error(erro);
      }
    };

    buscaTemplates();
  }, []);

  const selecionaTemplate = (texto: string) => {
    let textoTratado = "";
    const palavrasChave = ["{{Atendente}}", "{{Cliente}}"];
    let alterado = false;

    palavrasChave.forEach((palavraChave) => {
      // Cria uma expressão regular para a palavra-chave atual
      const regex = new RegExp(palavraChave, "i");

      // Verifica se o texto contém a palavra-chave
      if (regex.test(texto)) {
        if (palavraChave === "{{Atendente}}" && usuario) {
          textoTratado = texto.replace(`${palavraChave}`, usuario.nome);
          alterado = true;
        }
        if (palavraChave === "{{Cliente}}" && protocoloSelecionado) {
          textoTratado = texto.replace(
            `${palavraChave}`,
            protocoloSelecionado.de.nome
          );
          alterado = true;
        }
      }
    });

    if (!alterado) textoTratado = texto;

    setMensagemTemplate(textoTratado);
  };

  const aoPressionarEnter = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      if (mensagemTemplate !== "Selecione uma Mensagem") {
        setMensagem((prevMensagem) => prevMensagem + mensagemTemplate);
        abreAbaTemplate();
        darFocoAoTextarea();
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.cartao} onKeyDown={aoPressionarEnter} tabIndex={0}>
        <div className={styles.listaTemplates}>
          <ul>
            {templates.map((temp) => {
              const modeloSemChat = temp.modelo.replace("chat-", "");

              return (
                <li
                  key={temp._id}
                  onClick={() => selecionaTemplate(temp.texto)}
                >
                  {modeloSemChat}
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.mensagemTemplate}>
          <div className={styles.mensagemTemplate__body}>
            {mensagemTemplate}
          </div>
          <div className={styles.mensagemTemplate__rodape}>
            aperte ENTER utilizar o template
          </div>
        </div>
      </div>
    </div>
  );
}
