import { useState } from "react";
import styles from "./Report.module.css";
import moment, { Moment } from "moment";
import CardDashboard from "./CardDashboard";
import api from "../../../../utils/api";
import Botao from "../../../Shared/Botao";
import Loading from "../../../Shared/Loading";
import DatePickerPeriodo from "../../../Shared/DatePickerPeriodo";

interface dadosReport {
  minuta: number;
  comCel: number;
  semCel: number;
  enviadasBot: number;
  agendadasBot: number;
  agendadasHum: number;
  errosBot: number;
  semWhats: number;
}

export default function Report() {
  const token = sessionStorage.getItem("token");
  const [dataFinal, setDatafinal] = useState<Moment | null>(moment());
  const [dataInicial, setDataInicial] = useState<Moment | null>(moment());
  const [dadosReport, setDadosReport] = useState<dadosReport>({
    minuta: 0,
    comCel: 0,
    semCel: 0,
    enviadasBot: 0,
    agendadasBot: 0,
    agendadasHum: 0,
    errosBot: 0,
    semWhats: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  const buscaDados = async () => {
    try {
      setIsLoading(true);
      const resposta = await api.post(
        "/prerota/statusreport",
        {
          dataInicial: moment(dataInicial, "yyyy-MM-DD").format("DD/MM/yyyy"),
          dataFinal: moment(dataFinal, "yyyy-MM-DD").format("DD/MM/yyyy"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // envia o token do login para verificar se esta valido
          },
        }
      );
      const dados = resposta.data;

      setDadosReport({
        minuta: dados.totalMinutas,
        comCel: dados.comCelular,
        semCel: dados.semCelular,
        enviadasBot: dados.enviados,
        agendadasBot: dados.agendadoAuto,
        agendadasHum: dados.agendadoHumano,
        errosBot: dados.erroBot,
        semWhats: dados.erroMeta,
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.cabecalho}>
      <div className={styles.filtro}>
        <h3>Periodo:</h3>
        <DatePickerPeriodo
          value={[dataInicial, dataFinal]}
          aoAlterado={(valor) => {
            setDataInicial(valor ? valor[0] : moment()),
              setDatafinal(valor ? valor[1] : moment());
          }}
        />
        <Botao cor="primaria" onClick={buscaDados}>
          Buscar
        </Botao>
      </div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className={styles.corpo}>
          <div className={styles.itens}>
            <CardDashboard
              nome={"Minutas"}
              quantidade={dadosReport.minuta}
              cor={"verde"}
            />

            <CardDashboard
              nome={"Enviados"}
              quantidade={dadosReport.enviadasBot}
              cor={"verde"}
            />
            <CardDashboard
              nome={"Não Enviados"}
              quantidade={dadosReport.errosBot}
              cor={"vermelho"}
            />
            <CardDashboard
              nome={"S/ Whatsapp"}
              quantidade={dadosReport.semWhats}
              cor={"vermelho"}
            />
          </div>
          <div className={styles.itens}>
            <CardDashboard
              nome={"Com Celular"}
              quantidade={dadosReport.comCel}
              cor={"azul_escuro"}
            />
            <CardDashboard
              nome={"Sem Celular"}
              quantidade={dadosReport.semCel}
              cor={"laranja"}
            />
            <CardDashboard
              nome={"Ag. AUTO"}
              quantidade={dadosReport.agendadasBot}
              cor={"azul_escuro"}
            />
            <CardDashboard
              nome={"Ag. Manual"}
              quantidade={dadosReport.agendadasHum}
              cor={"azul_escuro"}
            />
          </div>
        </div>
      )}
    </div>
  );
}
