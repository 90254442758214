import styles from "./ConversasHeader.module.css";
import BotaoChat from "../../BotaoChat";
import api from "../../../../utils/api";
import { useUsuario } from "../../../../context/UsuarioContext";
import { useProtocolo } from "../../../../context/ProtocoloContext";
import { useMensagem } from "../../../../context/MensagensContext";
import { useState } from "react";
import ModalTransferir from "../../ModalTransferir";
import { useSocket } from "../../../../context/SocketContext";
import { Icon } from "@iconify-icon/react";

export default function ConversasHeader() {
  const { usuario } = useUsuario();
  const { online } = useSocket();
  const {
    setProtocoloSelecionado,
    protocoloSelecionado,
    atualizaEsperaAtendimento,
  } = useProtocolo();
  const { enviaMensagemTexto, apagaMensagensCache } = useMensagem();
  const [abreModalTranferir, setAbreModalTranferir] = useState<boolean>(false);

  const abrirFecharModalTransferir = () => {
    setAbreModalTranferir(!abreModalTranferir);
  };

  const finalizar = async () => {
    try {
      if (confirm("Tem certeza de que deseja finalizar o atendimento?")) {
        if (!online) {
          alert("Fique online para finalizar o Atendimento");
          return;
        }
        if (usuario && protocoloSelecionado) {
          const resposta = await api.put("/protocolo", {
            _id: protocoloSelecionado._id,
            status: "finalizado",
            atendente: usuario._id,
          });
          const dados = resposta.data;
          if (dados) {
            const mensagem = `Estamos finalizando nosso atendimento.\n\nCaso necessite de algum apoio posterior, estaremos à disposição!\n\nO protocolo do nosso atendimento: ${protocoloSelecionado.protocolo}`;
            enviaMensagemTexto(mensagem);
            setProtocoloSelecionado(undefined);
            apagaMensagensCache(protocoloSelecionado.protocolo);
            atualizaEsperaAtendimento();
            alert("Atendimento finalizado");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {abreModalTranferir && protocoloSelecionado && (
        <ModalTransferir
          abrirFecharModalTransferir={abrirFecharModalTransferir}
          abreModalTranferir={abreModalTranferir}
          protocoloSelecionado={protocoloSelecionado}
          setProtocoloSelecionado={setProtocoloSelecionado}
          atualizaEsperaAtendimento={atualizaEsperaAtendimento}
          usuario={usuario}
          apagaMensagensCache={apagaMensagensCache}
        />
      )}
      <div className={styles.container}>
        <div className={styles.nome_cliente}>
          <h4>{protocoloSelecionado?.de.nome}</h4>
        </div>
        <div className={styles.botoes}>
          {usuario && usuario.acesso === "Adm" && (
            <BotaoChat
              nome="Transferir"
              onClick={() => abrirFecharModalTransferir()}
            >
              <Icon icon="solar:transfer-horizontal-bold-duotone" />
            </BotaoChat>
          )}
          <BotaoChat nome={"Finalizar"} onClick={() => finalizar()}>
            <Icon icon="solar:chat-square-check-outline" />
          </BotaoChat>
        </div>
      </div>
    </>
  );
}
