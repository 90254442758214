import { useEffect } from "react";
import styles from "./Chat.module.css";
import Conversas from "../../components/Chat/Conversas";
import Informativo from "../../components/Chat/Informativo";
import Sidebar from "../../components/Chat/Sidebar";
import Navbar from "../../components/Shared/Navbar";
import { useProtocolo } from "../../context/ProtocoloContext";
import { useMensagem } from "../../context/MensagensContext";
import { useUsuario } from "../../context/UsuarioContext";

export default function Chat() {
  const token = sessionStorage.getItem("token");
  const { usuario, consultaUsuario } = useUsuario();
  const { protocoloSelecionado, atualizaEsperaAtendimento } = useProtocolo();
  const { buscaContatoBot } = useMensagem();

  useEffect(() => {
    if (!usuario && token) {
      const usuarioId = sessionStorage.getItem("usuarioId");
      if (usuarioId) {
        consultaUsuario(usuarioId);
      }
    }
  }, [token]);

  useEffect(() => {
    if (usuario) {
      atualizaEsperaAtendimento();
    }
  }, [usuario]);

  useEffect(() => {
    if (token) buscaContatoBot("SAC");
  }, [token]);

  return (
    <>
      <Navbar />
      <section className={styles.container}>
        <Sidebar />
        <Conversas protocoloSelecionado={protocoloSelecionado} />
        {protocoloSelecionado && (
          <Informativo protocoloSelecionado={protocoloSelecionado} />
        )}
      </section>
    </>
  );
}
