import styles from "./SideBarPainel.module.css";

interface SideBarPainelProps {
  opcoes: string[];
  opcaoSelecionada: string;
  setOpcaoSelecionada: (opcao: string) => void;
}

export default function SideBarPainel({
  opcoes,
  setOpcaoSelecionada,
  opcaoSelecionada,
}: SideBarPainelProps) {
  const selecionaOpcao = (opcao: string) => {
    setOpcaoSelecionada(opcao);
  };

  return (
    <div className={styles.container}>
      <ul className={styles.opcoes}>
        {opcoes.map((opcao) => {
          return (
            <li
              key={opcao}
              onClick={() => selecionaOpcao(opcao)}
              className={
                opcao === opcaoSelecionada ? styles.opcaoSelecionada : ""
              }
            >
              {opcao}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
