import { useEffect, useState } from "react";
import styles from "./InformativoHeader.module.css";
import InformativoOpcao from "../InformativoOpcao";

interface InformativoHeaderProps {
  setAbaAberta: (aba: string) => void;
  abaAberta: string;
}

export default function InformativoHeader({
  setAbaAberta,
  abaAberta,
}: InformativoHeaderProps) {
  const [geralAtivo, setGeralAtivo] = useState(true);
  const [historicoAtivo, setHistoricoAtivo] = useState(false);

  useEffect(() => {
    defineAtivo(abaAberta);
  }, [abaAberta]);

  const defineAtivo = (tipo: string) => {
    if (abaAberta === "Informacoes") {
      setHistoricoAtivo(false);
      setGeralAtivo(true);
      setAbaAberta(tipo);
    } else if (abaAberta === "Historico") {
      setHistoricoAtivo(true);
      setGeralAtivo(false);
      setAbaAberta(tipo);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.titulo}>
        <h2>Detalhes do Cliente</h2>
      </div>
      <div className={styles.opcoes}>
        <div className={styles.item} onClick={() => defineAtivo("Informacoes")}>
          <InformativoOpcao nome="Informações" ativo={geralAtivo} />
        </div>
        <div className={styles.item} onClick={() => defineAtivo("Historico")}>
          <InformativoOpcao nome="Histórico" ativo={historicoAtivo} />
        </div>
      </div>
    </div>
  );
}
