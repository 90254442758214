import styles from "./InformativoInformacoesMotorista.module.css";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import { useEffect, useState } from "react";
import api from "../../../../utils/api";
import CampoInput from "../../../Shared/CampoInput";
import Botao from "../../../Shared/Botao";
import axios from "axios";
import SelectInput from "../../../Shared/SelectInput";

interface InformativoInformacoesProps {
  protocoloSelecionado: Protocolo;
}

interface IDadosCliente {
  minuta?: string;
  chaveNfe?: string;
  nomeCliente?: string;
  telefones?: string[];
  endereco?: {
    rua?: string;
    numero?: string;
    bairro?: string;
    cidade?: string;
    estado?: string;
    cep?: string;
  };
}

export default function InformativoInformacoesMotorista({
  protocoloSelecionado,
}: InformativoInformacoesProps) {
  const [dadosCliente, setDadosCliente] = useState<IDadosCliente[]>([]);
  const [numeroMinuta, setNumeroMinuta] = useState("");
  const [tipo, setTipo] = useState("Minuta");

  useEffect(() => {
    setDadosCliente([]);
    setNumeroMinuta("");
    setTipo("Minuta");
    if (protocoloSelecionado.minuta) {
      setNumeroMinuta(protocoloSelecionado.minuta);
      popularDadosMinuta(protocoloSelecionado.minuta);
    }
  }, [protocoloSelecionado]);

  const popularDadosMinuta = async (minuta: string) => {
    try {
      setDadosCliente([]);
      if (minuta) {
        const resposta = await api.get(
          `/minuta/popular/${minuta.trim()}/${tipo.toLowerCase()}`
        );
        const dados = resposta.data;
        if (dados) {
          setDadosCliente(dados);
        }
      }
    } catch (error: unknown) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        if (error.response?.data?.message) {
          alert(error.response.data.message);
        }
      }
      setDadosCliente([]);
    }
  };

  return (
    <div className={styles.card}>
      <ul className={styles.lista}>
        <SelectInput
          aoAlterado={setTipo}
          opcoes={["Minuta", "Nfe"]}
          value={tipo}
        ></SelectInput>
        <div className={styles.buscar}>
          <CampoInput
            type="text"
            aoAlterado={(e) => setNumeroMinuta(e)}
            value={numeroMinuta}
            required={true}
            placeholder="Buscar Minuta"
          />
          <Botao
            cor={"primaria"}
            onClick={() => popularDadosMinuta(numeroMinuta)}
          >
            Buscar
          </Botao>
        </div>

        <li>
          <span>Protocolo: </span> {protocoloSelecionado.protocolo}
        </li>
        <li>
          <span>Origem: </span> {protocoloSelecionado.origem}
        </li>
        <li>
          <span>Tel: </span> {protocoloSelecionado.de.telefone}
        </li>
        {protocoloSelecionado.de.placa && (
          <li>
            <span>Placa: </span> {protocoloSelecionado.de.placa}
          </li>
        )}
        {protocoloSelecionado.manifesto && (
          <li>
            <span>Manifesto: </span> {protocoloSelecionado.manifesto}
          </li>
        )}

        {dadosCliente.length > 0 &&
          dadosCliente.map((dados, index) => {
            return (
              <div className={styles.lista} key={index}>
                {dados && <div className={styles.pipe}></div>}
                {dados?.chaveNfe && (
                  <li>
                    <span>NFe: </span>{" "}
                    {dados?.chaveNfe && dados?.chaveNfe.length >= 44
                      ? dados?.chaveNfe.substring(25, 34)
                      : " - "}
                  </li>
                )}

                {dados?.minuta && (
                  <li>
                    <span>Minuta: </span>
                    {dados.minuta}
                  </li>
                )}
                {dados?.nomeCliente && (
                  <li>
                    <span>Nome: </span>
                    {dados.nomeCliente}
                  </li>
                )}
                {dados?.telefones && (
                  <li>
                    <span>Telefones: </span>
                    {dados.telefones.map((tel, index) => {
                      return <p key={index}>{tel}</p>;
                    })}
                  </li>
                )}
                {dados?.endereco?.rua && (
                  <li>
                    <span>Rua: </span>
                    {dados.endereco.rua}
                  </li>
                )}

                {dados?.endereco?.numero && (
                  <li>
                    <span>Numero: </span>
                    {dados.endereco.numero}
                  </li>
                )}

                {dados?.endereco?.bairro && (
                  <li>
                    <span>Bairro: </span>
                    {dados.endereco.bairro}
                  </li>
                )}

                {dados?.endereco?.cidade && (
                  <li>
                    <span>Cidade: </span>
                    {dados.endereco.cidade}
                  </li>
                )}

                {dados?.endereco?.estado && (
                  <li>
                    <span>Estado: </span>
                    {dados.endereco.estado}
                  </li>
                )}

                {dados?.endereco?.cep && (
                  <li>
                    <span>Cep: </span>
                    {dados.endereco.cep}
                  </li>
                )}
              </div>
            );
          })}
      </ul>
      <div className={styles.pipe}></div>
    </div>
  );
}
