import { useEffect, useState } from "react";
import styles from "./GerenciarUsuarios.module.css";
import api from "../../../utils/api";
import { Usuario } from "../../../interfaces/IUsuario";
import { MdEdit } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";
import ModalAdicionarUsuario from "./ModalAdicionarUsuario";

export default function GerenciarUsuarios() {
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [abrirModalAdicionar, setAbrirModalAdicionar] =
    useState<boolean>(false);

  useEffect(() => {
    if (usuarios.length === 0) {
      consultaUsuarios();
    }
  }, [usuarios]);

  const consultaUsuarios = async () => {
    try {
      const resposta = await api.get("/usuario/");
      const dados = resposta.data;

      if (dados) {
        setUsuarios(dados);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const abreFechaModalAdicionar = () => {
    setAbrirModalAdicionar(!abrirModalAdicionar);
  };

  const abrirModalEditar = () => {
    alert("Em Construção");
  };

  const abrirModalDesativar = () => {
    alert("Em Construção");
  };

  return (
    <>
      {abreFechaModalAdicionar && (
        <ModalAdicionarUsuario
          abreModal={abrirModalAdicionar}
          abreFechaModal={abreFechaModalAdicionar}
        />
      )}
      <div className={styles.container}>
        <h2>Usuarios</h2>
        <div className={styles.adicionar}>
          <span onClick={() => abreFechaModalAdicionar()}>adicionar</span>
        </div>
        <ul>
          {usuarios
            .sort((a, b) => a.nome.localeCompare(b.nome)) // Ordenar os usuários pelo nome
            .map((usuario) => {
              return (
                <li key={usuario._id}>
                  <span>{usuario.nome}</span>
                  <div className={styles.botoes}>
                    <MdEdit onClick={() => abrirModalEditar()} />
                    <AiOutlineStop onClick={() => abrirModalDesativar()} />
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
}
