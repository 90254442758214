import { FormEvent } from "react";
import styles from "./Botao.module.css";

interface Props {
  cor: string;
  children: string;
  onClick: (e: FormEvent) => void;
}

export default function Botao({ cor, children, onClick }: Props) {
  const botaoClass = `${styles.botao} ${styles[`botao-${cor}`]}`;
  return (
    <div className={botaoClass} onClick={onClick}>
      {children}
    </div>
  );
}
