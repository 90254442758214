import styles from "./InformativoOpcao.module.css";

interface InformativoOpcaoProps {
  nome: string;
  ativo: boolean;
}

export default function InformativoOpcao({
  nome,
  ativo,
}: InformativoOpcaoProps) {
  return (
    <div className={`${styles.container} ${ativo ? styles.ativo : ""}`}>
      <span>{nome}</span>
    </div>
  );
}
