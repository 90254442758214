import styles from "./Loading.module.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Loading() {
  return (
    <div className={styles.loading}>
      <AiOutlineLoading3Quarters className={styles.loader} />
      <p>Carregando...</p>
    </div>
  );
}
