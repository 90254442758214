import { useEffect } from "react";
import styles from "./MonitoramentoAtendentes.module.css";
import { useDashboard } from "../../../../context/DashboardContext";

export default function MonitoramentoAtendentes() {
  const { populaAtendentes, dadosAtendente } = useDashboard();

  useEffect(() => {
    populaAtendentes();
  }, []);

  const atendentesOrdenado = dadosAtendente.sort((a, b) =>
    a.atendente.localeCompare(b.atendente)
  );

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th className={styles.atendente}>Atendente</th>
            <th className={styles.atendimento}>Em Atendimento</th>
            <th className={styles.finalizado}>Finalizado</th>
            <th className={styles.resposta}>Tempo Médio Resposta</th>
            <th className={styles.tempoAtendimento}>Tempo Médio Atendimento</th>
          </tr>
        </thead>
        <tbody>
          {dadosAtendente &&
            atendentesOrdenado.map((item) => {
              return (
                <tr className={styles.tabela_conteudo} key={item.atendente}>
                  <td className={styles.atendente}>{item.atendente}</td>
                  <td className={styles.atendimento}>{item.atendimento}</td>
                  <td className={styles.finalizado}>{item.finalizado}</td>
                  <td className={styles.resposta}>{item.tmr}</td>
                  <td className={styles.tempoAtendimento}>{item.tma}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
