import { useEffect, useState } from "react";
import styles from "./InformativoHistorico.module.css";
import moment from "moment";
import api from "../../../../utils/api";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import InformativoHistoricoConversa from "../InformativoHistoricoConversa";

interface InformativoHistoricoProps {
  protocoloSelecionado: Protocolo;
}

export default function InformativoHistorico({
  protocoloSelecionado,
}: InformativoHistoricoProps) {
  const [filasHistorico, setFilasHistorico] = useState<Protocolo[]>([]);
  const [historicoSelecionado, setHistoricoSelecionado] = useState<Protocolo>();

  useEffect(() => {
    if (protocoloSelecionado) {
      buscaHistorico();
    }
  }, [protocoloSelecionado]);

  const buscaHistorico = async () => {
    try {
      const resposta = await api.get(
        `protocolo/historico/${protocoloSelecionado.de._id}`
      );
      setFilasHistorico(resposta.data);
    } catch (error) {
      console.log(error);
    }
  };

  const selecionaProtocolo = async (protocolo: Protocolo) => {
    setHistoricoSelecionado(protocolo);
  };

  return (
    <div className={styles.container}>
      {historicoSelecionado ? (
        <InformativoHistoricoConversa
          protocoloHistorico={historicoSelecionado}
          setProtocoloHistorico={setHistoricoSelecionado}
        />
      ) : (
        <div className={styles.protocolos}>
          <div className={styles.cabecalho}>
            <span>Protocolos</span>
          </div>
          {filasHistorico &&
            filasHistorico.map((protocolo: Protocolo) => {
              return (
                <>
                  <div
                    key={protocolo._id}
                    className={styles.card}
                    onClick={() => selecionaProtocolo(protocolo)}
                  >
                    <span key={protocolo.protocolo}>{protocolo.protocolo}</span>
                    <div className={styles.rodape}>
                      <p>{protocolo.origem.toUpperCase()}</p>
                      <p>{moment(protocolo.criadoEm).format("DD/MM/yyyy")}</p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      )}
    </div>
  );
}
