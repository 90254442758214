import styles from "./Paginacao.module.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

interface PaginacaoProps {
  paginaAtual: number;
  setPaginaAtual: (numero: number) => void;
  totalPaginas: number;
}

export default function Paginacao({
  paginaAtual,
  setPaginaAtual,
  totalPaginas,
}: PaginacaoProps) {
  const paginaAnterior = () => {
    if (paginaAtual > 1) {
      setPaginaAtual(paginaAtual - 1);
    }
  };

  const proximaPagina = () => {
    if (paginaAtual < totalPaginas) {
      setPaginaAtual(paginaAtual + 1);
    }
  };

  const selecionarPagina = (pagina: number) => {
    if (paginaAtual < totalPaginas) {
      setPaginaAtual(pagina);
    }
  };

  // Determinando o índice inicial e final para exibir os botões das páginas
  let inicio = Math.max(1, paginaAtual - 2);
  let fim = Math.min(totalPaginas, inicio + 4);

  // Se o início estiver muito próximo da última página, ajustamos o início para garantir que exibamos sempre 5 páginas
  if (totalPaginas - inicio < 4) {
    inicio = Math.max(1, totalPaginas - 4);
    fim = totalPaginas;
  }

  // Array para armazenar os botões das páginas
  const paginas = [];

  // Loop para criar botões para cada página no intervalo definido
  for (let i = inicio; i <= fim; i++) {
    paginas.push(
      <button
        key={i}
        onClick={() => selecionarPagina(i)}
        className={paginaAtual === i ? styles.paginaAtual : styles.paginas}
      >
        {i}
      </button>
    );
  }

  return (
    <div className={styles.paginacao}>
      <button
        className={styles.alteraPagina}
        onClick={paginaAnterior}
        disabled={paginaAtual === 1}
      >
        <FaChevronLeft />
      </button>
      {paginas}
      <button
        className={styles.alteraPagina}
        onClick={proximaPagina}
        disabled={paginaAtual === totalPaginas}
      >
        <FaChevronRight />
      </button>
    </div>
  );
}
