import { Select } from "antd";
import { ConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";

interface props {
  value: string[];
  aoAlterado: (texto: string[]) => void;
  opcoes: string[];
  placeholder?: string;
}

export default function SelectGrupo({
  value,
  aoAlterado,
  opcoes,
  placeholder,
}: props) {
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filteredOptions = opcoes.filter((o) => !value.includes(o));

  return (
    <ConfigProvider locale={ptBR}>
      <Select
        mode="multiple"
        value={value}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={(newValue) => {
          if (newValue.includes("Todos")) {
            if (newValue[0] === "Todos" && newValue.length > 1) {
              aoAlterado(newValue.filter((v) => v !== "Todos"));
            } else {
              aoAlterado(["Todos"]);
            }
          } else {
            aoAlterado(newValue);
          }
        }}
        filterOption={filterOption}
        options={filteredOptions.map((item) => ({
          value: item,
          label: item,
        }))}
      />
    </ConfigProvider>
  );
}
