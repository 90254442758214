import { useState } from "react";
import styles from "./EnvioUnico.module.css";
import DatePickerUnico from "../../../Shared/DatePickerUnico";
import moment, { Moment } from "moment";
import CampoInput from "../../../Shared/CampoInput";
import Botao from "../../../Shared/Botao";
import api from "../../../../utils/api";
import Loading from "../../../Shared/Loading";

interface IEnvioUnico {
  dataAgendamento: Moment;
  nome: string;
  telefone: string;
  minuta: string;
}

export default function EnvioUnico() {
  const [loading, setLoading] = useState(false);
  const [envioUnico, setEnvioUnico] = useState<IEnvioUnico>({
    dataAgendamento: moment().add(1, "day"),
    nome: "",
    telefone: "",
    minuta: "",
  });

  const enviarMensagem = async () => {
    setLoading(true);
    try {
      const resposta = await api.post("/prerota/envioUnico", {
        dataAgendamento: envioUnico.dataAgendamento.format("DD/MM/yyyy"),
        nome: envioUnico.nome.trim(),
        telefone: envioUnico.telefone.trim(),
        minuta: envioUnico.minuta.trim(),
      });

      const dados = resposta.data;
      if (dados) {
        alert("Enviando Mensagem");
      }

      setEnvioUnico((prev) => ({
        ...prev,
        nome: "",
        telefone: "",
        minuta: "",
      }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      alert(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.titulo}>
        <h3>Envio Unico</h3>
      </div>
      <form className={styles.conteudo} onSubmit={enviarMensagem}>
        <label>Data Agendamento:</label>
        <DatePickerUnico
          value={envioUnico.dataAgendamento}
          aoAlterado={(valor) =>
            setEnvioUnico((prev) => ({ ...prev, dataAgendamento: valor }))
          }
        />
        <label>Nome:</label>
        <CampoInput
          required={true}
          type={"text"}
          value={envioUnico.nome}
          aoAlterado={(valor) =>
            setEnvioUnico((prev) => ({ ...prev, nome: valor }))
          }
        />
        <label>Telefone:</label>
        <CampoInput
          required={true}
          type={"text"}
          value={envioUnico.telefone}
          aoAlterado={(valor) =>
            setEnvioUnico((prev) => ({ ...prev, telefone: valor }))
          }
        />
        <label>Minuta:</label>
        <CampoInput
          required={true}
          type={"text"}
          value={envioUnico.minuta}
          aoAlterado={(valor) =>
            setEnvioUnico((prev) => ({ ...prev, minuta: valor }))
          }
        />

        {loading ? (
          <Loading />
        ) : (
          <Botao cor={"primaria"} onClick={enviarMensagem}>
            Enviar
          </Botao>
        )}
      </form>
    </div>
  );
}
