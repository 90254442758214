import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { ConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

interface props {
  value: Moment | null;
  aoAlterado: (valor: Moment) => void;
}

export default function DatePickerUnico({ value, aoAlterado }: props) {
  return (
    <ConfigProvider locale={ptBR}>
      <MyDatePicker
        placeholder="Selecione a data"
        onChange={(date: Moment | null) => aoAlterado(date || moment())}
        value={value}
        format={"DD/MM/yyyy"}
      />
    </ConfigProvider>
  );
}
