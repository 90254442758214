import { useProtocolo } from "../../../../context/ProtocoloContext";
import SidebarContatos from "../SidebarContatos";
import styles from "./SidebarBody.module.css";

export default function SidebarBody() {
  const { protocolosEmAtendimento } = useProtocolo();

  const protocolosOrdenados =
    protocolosEmAtendimento.length > 0
      ? [...protocolosEmAtendimento].sort((a, b) => {
          const dateA = a.atualizadoEm
            ? new Date(a.atualizadoEm)
            : new Date(a.criadoEm);
          const dateB = b.atualizadoEm
            ? new Date(b.atualizadoEm)
            : new Date(b.criadoEm);
          return dateA.getTime() - dateB.getTime();
        })
      : [];

  return (
    <div className={styles.container}>
      {protocolosOrdenados.map((protocolo) => {
        return <SidebarContatos key={protocolo._id} protocolo={protocolo} />;
      })}
    </div>
  );
}
