import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { io, Socket } from "socket.io-client";
import { API_URL } from "../utils/api";
import { Mensagem } from "../interfaces/IMensagem";
import { useMensagem } from "./MensagensContext";
import { useProtocolo } from "./ProtocoloContext";
import { useUsuario } from "./UsuarioContext";
import { useDashboard } from "./DashboardContext";
import { ICardsProtocolo } from "../interfaces/ICardProtocolo";
const socketOptions = {
  path: "/api/socket.io", // Caminho especificado no backend
};

interface SocketContextProps {
  socket: Socket | null;
  online: boolean;
  entrarNaSala: (sala: string) => void;
  sairDaSala: () => void;
}

const SocketContext = createContext<SocketContextProps | undefined>(undefined);

export const SocketProvider: React.FC<{ children: ReactNode }> = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const [socket, setSocket] = useState<Socket>(io());
  const [online, setOnline] = useState<boolean>(false);
  const { usuario } = useUsuario();
  const { setCardsProtocolo } = useDashboard();
  const { adicionaMensagemCache, atualizaStatus, atualizaMensagem } =
    useMensagem();
  const {
    setAguardando,
    protocoloSelecionado,
    adicionaNotificacao,
    notificacao,
    setAguardandoMotorista,
  } = useProtocolo();

  useEffect(() => {
    if (socket) {
      const mensagemAtendente = (mensagem: Mensagem) => {
        if (
          protocoloSelecionado &&
          protocoloSelecionado.protocolo === mensagem.protocolo
        ) {
          atualizaMensagem(mensagem);
        } else {
          adicionaNotificacao(mensagem.protocolo);
        }
        adicionaMensagemCache(mensagem);
      };

      const statusMensagem = (status: Mensagem) => {
        if (
          protocoloSelecionado &&
          protocoloSelecionado.protocolo === status.protocolo
        ) {
          atualizaStatus(status);
        }
      };

      const protocoloEspera = (protocolo: number) => {
        setAguardando(protocolo);
      };

      const protocoloEsperaMotorista = (protocolo: number) => {
        setAguardandoMotorista(protocolo);
      };

      const populaDashboard = (cardsProtocolo: ICardsProtocolo) => {
        setCardsProtocolo(cardsProtocolo);
      };

      // Conectar aos sockets e adicionar os ouvintes
      socket.on(`mensagem-atendente-${usuario?._id}`, mensagemAtendente);
      socket.on(`status-mensagem-${usuario?._id}`, statusMensagem);
      socket.on("protocolo-espera", protocoloEspera);
      socket.on("protocolo-espera-motorista", protocoloEsperaMotorista);
      socket.on(`dashboard-cards`, populaDashboard);

      // Retire os ouvintes quando o componente for desmontado
      return () => {
        socket.off(`mensagem-atendente-${usuario?._id}`, mensagemAtendente);
        socket.off(`status-mensagem-${usuario?._id}`, statusMensagem);
        socket.off("protocolo-espera", protocoloEspera);
        socket.off("protocolo-espera-motorista", protocoloEsperaMotorista);
        socket.off(`dashboard-cards`, populaDashboard);
      };
    }
  }, [socket, protocoloSelecionado, notificacao]);

  const entrarNaSala = async () => {
    try {
      if (!socket.connected) {
        const newSocket = io(`${API_URL}`, socketOptions);
        // Reconectar se o socket não existir ou não estiver conectado
        setSocket(newSocket);
      }

      if (socket) {
        socket.on("server-message", (message) => {
          console.log(message);
        });
        setOnline(true);
      }
    } catch (error) {
      console.error("Erro ao entrar na sala:", error);
    }
  };

  const sairDaSala = async () => {
    if (socket) {
      socket.off("chat-atendente");
      socket.disconnect();
      setOnline(false);
    } else {
      console.error("Socket não inicializado.");
    }
  };

  return (
    <SocketContext.Provider
      value={{
        socket,
        entrarNaSala,
        sairDaSala,
        online,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export function useSocket() {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error("useSocket deve ser usado dentro de um SocketProvider");
  }

  return context;
}
