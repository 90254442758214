import { ReactNode } from "react";
import styles from "./BotaoChat.module.css";

interface BotaoChatProps {
  children: ReactNode;
  onClick: () => void;
  nome: string;
}

export default function BotaoChat({ children, onClick, nome }: BotaoChatProps) {
  return (
    <div className={styles.botao__chat} onClick={onClick}>
      <div className={styles.icone}>{children}</div>
      <span>{nome}</span>
    </div>
  );
}
