import styles from "./InformativoBodyMotorista.module.css";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import InformativoInformacoesMotorista from "../InformativoInformacoesMotorista";
import InformativoOcorrenciaMotorista from "../InformativoOcorrenciaMotorista";

interface InformativoBodyProps {
  protocoloSelecionado: Protocolo;
}

export default function InformativoBodyMotorista({
  protocoloSelecionado,
}: InformativoBodyProps) {
  return (
    <div className={styles.container}>
      <InformativoInformacoesMotorista
        protocoloSelecionado={protocoloSelecionado}
      />

      <InformativoOcorrenciaMotorista
        protocoloSelecionado={protocoloSelecionado}
      />
    </div>
  );
}
