import { useState } from "react";
import { Protocolo } from "../../../interfaces/IProtocolo";
import AtendimentosItens from "./AtendimentosItens";
import styles from "./Atendimentos.module.css";
import Paginacao from "../../Shared/Paginacao";
import AtendimentosFiltro from "./AtendimentosFiltro";
import ModalAtendimentos from "./ModalAtendimentos";
import Loading from "../../Shared/Loading";
import { useUsuario } from "../../../context/UsuarioContext";

interface resultadoBuscaProps {
  protocolos: Protocolo[];
  totalPaginas: number;
  paginaAtual: number;
  itensPorPagina: number;
  totalItens: number;
  loading: boolean;
}

export default function Atendimentos() {
  const [resultadoBusca, setResultadoBusca] = useState<resultadoBuscaProps>({
    protocolos: [],
    totalPaginas: 1,
    paginaAtual: 1,
    itensPorPagina: 20,
    totalItens: 0,
    loading: false,
  });
  const [protocoloSelecionado, setProtocoloSelecionado] = useState<Protocolo>();
  const [abrirModalAtendimentos, setAbrirModalAtendimentos] =
    useState<boolean>(false);
  const { usuarios } = useUsuario();

  const selecionaProtocolo = (protocolo: Protocolo) => {
    setProtocoloSelecionado(protocolo);
    abreFechaModal();
  };

  const abreFechaModal = () => {
    setAbrirModalAtendimentos(!abrirModalAtendimentos);
  };

  const selecionaAtendente = (id?: string) => {
    try {
      if (id) {
        const usuario = usuarios.find((user) => user._id === id);
        return usuario?.nome || " ";
      } else {
        return "TsChatBot";
      }
    } catch (error) {
      console.log(error);
      return "Erro";
    }
  };

  return (
    <>
      {abrirModalAtendimentos && (
        <ModalAtendimentos
          abreModal={abrirModalAtendimentos}
          abreFechaModal={abreFechaModal}
          protocoloSelecionado={protocoloSelecionado}
        />
      )}
      <div className={styles.container}>
        <div className={styles.conteudo}>
          <AtendimentosFiltro
            resultadoBusca={resultadoBusca}
            setResultadoBusca={setResultadoBusca}
          />
          <span className={styles.itens}>
            Itens: {resultadoBusca.totalItens}
          </span>
          {resultadoBusca.loading ? (
            <Loading />
          ) : (
            <>
              {resultadoBusca.protocolos.length > 0
                ? resultadoBusca.protocolos.map((protocolo) => {
                    const atendente = selecionaAtendente(protocolo.atendente);
                    return (
                      <div
                        key={protocolo._id}
                        onClick={() => selecionaProtocolo(protocolo)}
                      >
                        <AtendimentosItens
                          protocolo={protocolo}
                          atendente={atendente}
                        />
                      </div>
                    );
                  })
                : "Sem Dados"}
            </>
          )}
        </div>
        {resultadoBusca.totalPaginas > 0 && (
          <div className={styles.paginacao}>
            <Paginacao
              paginaAtual={resultadoBusca.paginaAtual}
              setPaginaAtual={(e) =>
                setResultadoBusca((prev) => ({ ...prev, paginaAtual: e }))
              }
              totalPaginas={resultadoBusca.totalPaginas}
            />
          </div>
        )}
      </div>
    </>
  );
}
