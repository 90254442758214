import Modal from "react-modal";
import { useEffect, useState } from "react";
import styles from "./ModalTransferir.module.css";
import Botao from "../../Shared/Botao";
import { Usuario } from "../../../interfaces/IUsuario";
import api from "../../../utils/api";
import Select from "../../Shared/Select";
import { Protocolo } from "../../../interfaces/IProtocolo";

interface ModalTransferirProps {
  abreModalTranferir: boolean;
  usuario: Usuario | null;
  protocoloSelecionado: Protocolo;
  abrirFecharModalTransferir: () => void;
  setProtocoloSelecionado: (protocolo: Protocolo | undefined) => void;
  atualizaEsperaAtendimento: () => void;
  apagaMensagensCache: (protocolo: string) => void;
}

export default function ModalTransferir({
  abreModalTranferir,
  abrirFecharModalTransferir,
  protocoloSelecionado,
  setProtocoloSelecionado,
  atualizaEsperaAtendimento,
  usuario,
  apagaMensagensCache,
}: ModalTransferirProps) {
  const [transferirPara, setTransferirPara] = useState("");
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);
  const [opcoes, setOpcoes] = useState(["", "Espera"]);

  useEffect(() => {
    buscaUsuarios();
  }, [usuarios]);

  const buscaUsuarios = async () => {
    try {
      const resposta = await api.get("/usuario");
      const dados: Usuario[] = resposta.data;
      setUsuarios(dados);
      const lista = ["", "Fila de Espera"];
      for (const usuario of dados) {
        lista.push(usuario.nome);
      }

      setOpcoes(lista);
    } catch (error) {
      console.log(error);
    }
  };

  const transferir = async () => {
    try {
      if (transferirPara && usuario) {
        const usuarioEncontrado = usuarios.find(
          (u) => u.nome === transferirPara
        );

        if (usuarioEncontrado) {
          const resposta = await api.put("/protocolo", {
            _id: protocoloSelecionado._id,
            status: "atendimento",
            atendente: usuarioEncontrado._id,
          });
          const dados = resposta.data;
          if (dados) {
            setProtocoloSelecionado(undefined);
            apagaMensagensCache(protocoloSelecionado.protocolo);
            atualizaEsperaAtendimento();
            alert("Transferido com sucesso");
          }
        } else if (transferirPara === "Fila de Espera") {
          const resposta = await api.put("/protocolo", {
            _id: protocoloSelecionado._id,
            status: "espera",
          });
          const dados = resposta.data;
          if (dados) {
            setProtocoloSelecionado(undefined);
            apagaMensagensCache(protocoloSelecionado.protocolo);
            atualizaEsperaAtendimento();
            alert("Transferido com sucesso");
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={abreModalTranferir}
        ariaHideApp={false}
      >
        <div className={styles.formulario}>
          <div className={styles.header}>
            <h2>Transferir</h2>
          </div>
          <div className={styles.body}>
            <Select
              required={true}
              label={"Transferir para:"}
              value={transferirPara}
              opcoes={opcoes}
              aoAlterado={(value) => setTransferirPara(value)}
            />
          </div>
          <div className={styles.footer}>
            <Botao cor="primaria" onClick={transferir}>
              Transferir
            </Botao>
            <Botao cor="vermelho" onClick={abrirFecharModalTransferir}>
              Cancelar
            </Botao>
          </div>
        </div>
      </Modal>
    </div>
  );
}
