import React, { ReactNode, createContext, useContext, useState } from "react";
import api from "../utils/api";
import { ICardsProtocolo } from "../interfaces/ICardProtocolo";

interface IEmAtendimento {
  tme: string;
  tma: string;
  tmr: string;
  cliente: string;
  atendente: string;
  protocolo: string;
}
interface IEmEspera {
  tme: string;
  cliente: string;
  protocolo: string;
}
interface IAtendentes {
  atendente: string;
  atendimento: string;
  finalizado: string;
  tmr: string;
  tma: string;
}
interface ICardsTempoMedio {
  ta: {
    maxTA: string;
    minTA: string;
    media: string;
  };
  te: {
    maxTE: string;
    minTE: string;
    media: string;
  };
  tr: {
    maxTR: string;
    minTR: string;
    media: string;
  };
}
interface DashboardProviderProps {
  children: ReactNode;
}
interface DashboardContextProps {
  atualizar: () => void;
  opcaoSelecionada: string;
  setOpcaoSelecionada: React.Dispatch<React.SetStateAction<string>>;
  monitoramentoOpcoes: string[];
  cardsProtocolo: ICardsProtocolo;
  setCardsProtocolo: React.Dispatch<React.SetStateAction<ICardsProtocolo>>;
  cardsTempoMedio: ICardsTempoMedio;
  emAtendimento: IEmAtendimento[];
  emEspera: IEmEspera[];
  dadosAtendente: IAtendentes[];
  populaCardsProtocolos: () => void;
  populaCardsTempoMedio: () => void;
  populaEmAtendimento: () => void;
  populaEmEspera: () => void;
  populaAtendentes: () => void;
}

const DashboardContext = createContext<DashboardContextProps | undefined>(
  undefined
);

export function DashboardProvider({ children }: DashboardProviderProps) {
  const [opcaoSelecionada, setOpcaoSelecionada] = useState("Em Atendimento");
  const monitoramentoOpcoes = [
    "Em Atendimento",
    "Em Espera",
    "Atendentes",
    "Gráficos",
  ];
  const [cardsProtocolo, setCardsProtocolo] = useState<ICardsProtocolo>({
    ura: 0,
    espera: 0,
    atendimento: 0,
    finalizado: 0,
    online: 0,
  });
  const [cardsTempoMedio, setCardsTempoMedio] = useState<ICardsTempoMedio>({
    ta: {
      maxTA: "00:00:00",
      minTA: "00:00:00",
      media: "00:00:00",
    },
    te: {
      maxTE: "00:00:00",
      minTE: "00:00:00",
      media: "00:00:00",
    },
    tr: {
      maxTR: "00:00:00",
      minTR: "00:00:00",
      media: "00:00:00",
    },
  });
  const [emAtendimento, setEmAtendimento] = useState<IEmAtendimento[]>([]);
  const [emEspera, setEmEspera] = useState<IEmEspera[]>([]);
  const [dadosAtendente, setDadosAtendente] = useState<IAtendentes[]>([]);

  const atualizar = () => {
    try {
      populaCardsProtocolos();
      populaCardsTempoMedio();
      if (opcaoSelecionada === "Em Atendimento") populaEmAtendimento();
      else if (opcaoSelecionada === "Em Espera") populaEmEspera();
      else if (opcaoSelecionada === "Atendentes") populaAtendentes();
    } catch (error) {
      console.log(error);
    }
  };

  const populaCardsProtocolos = async () => {
    try {
      const resultado = await api.get("dashboard/");
      const dados = resultado.data;

      setCardsProtocolo({
        ura: dados.ura,
        espera: dados.espera,
        atendimento: dados.atendimento,
        finalizado: dados.finalizado,
        online: dados.online,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const populaCardsTempoMedio = async () => {
    try {
      const resultado = await api.get("dashboard/tempomedio");
      const dados = resultado.data;

      setCardsTempoMedio(dados);
    } catch (error) {
      console.log(error);
    }
  };

  const populaEmAtendimento = async () => {
    try {
      const resposta = await api.get("/dashboard/atendimento");
      const dados = resposta.data;

      setEmAtendimento(dados);
    } catch (error) {
      console.log(error);
    }
  };

  const populaEmEspera = async () => {
    try {
      const resposta = await api.get("/dashboard/espera");
      const dados = resposta.data;

      setEmEspera(dados);
    } catch (error) {
      console.log(error);
    }
  };

  const populaAtendentes = async () => {
    try {
      const resposta = await api.get("/dashboard/atendentes");
      const dados = resposta.data;

      setDadosAtendente(dados);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        atualizar,
        opcaoSelecionada,
        setOpcaoSelecionada,
        monitoramentoOpcoes,
        cardsProtocolo,
        setCardsProtocolo,
        cardsTempoMedio,
        emAtendimento,
        emEspera,
        dadosAtendente,
        populaCardsProtocolos,
        populaCardsTempoMedio,
        populaEmAtendimento,
        populaEmEspera,
        populaAtendentes,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboard() {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error(
      "useDashboard deve ser utilizado dentro de um DashboardProvider"
    );
  }
  return context;
}
