import Botao from "../../../Shared/Botao";
import styles from "./AtendimentosFiltro.module.css";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import moment, { Moment } from "moment";
import SelectInput from "../../../Shared/SelectInput";
import DatePickerPeriodo from "../../../Shared/DatePickerPeriodo";
import { useUsuario } from "../../../../context/UsuarioContext";
import api from "../../../../utils/api";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import { useDepartamento } from "../../../../context/DepartamentoContext";

interface resultadoBuscaProps {
  protocolos: Protocolo[];
  totalPaginas: number;
  paginaAtual: number;
  itensPorPagina: number;
  totalItens: number;
  loading: boolean;
}

interface filtroProps {
  protocolo: string;
  minuta: string;
  origem: string;
  status: string;
  atendente: string;
  setor: string;
  dataInicio: Moment | null;
  dataFinal: Moment | null;
}

interface AtendimentosFiltroProps {
  resultadoBusca: resultadoBuscaProps;
  setResultadoBusca: Dispatch<SetStateAction<resultadoBuscaProps>>;
}

export default function AtendimentosFiltro({
  resultadoBusca,
  setResultadoBusca,
}: AtendimentosFiltroProps) {
  const { opcoesDepartamento } = useDepartamento();
  const { usuarios, consultaUsuarios } = useUsuario();
  const [filtro, setFiltro] = useState<filtroProps>({
    protocolo: "",
    minuta: "",
    origem: "Todos",
    status: "Todos",
    atendente: "Todos",
    setor: "Todos",
    dataInicio: moment(),
    dataFinal: moment(),
  });

  useEffect(() => {
    if (usuarios.length === 0) {
      consultaUsuarios();
    }

    buscar();
  }, [usuarios, resultadoBusca.paginaAtual]);

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      buscar();
    }
  };

  const buscar = async () => {
    try {
      setResultadoBusca((prev) => ({ ...prev, loading: true }));
      let usuario = null;

      if (filtro.atendente !== "Todos") {
        const atend = usuarios.find((user) => user.nome === filtro.atendente);
        if (atend) {
          usuario = atend._id;
        } else {
          usuario = "ChatBot";
        }
      }

      const setor = opcoesDepartamento.find(
        (setor) => setor.nome === filtro.setor
      );

      const resposta = await api.post("/protocolo/paginacao", {
        pagina: resultadoBusca.paginaAtual,
        itensPagina: resultadoBusca.itensPorPagina,
        protocolo: filtro.protocolo ? filtro.protocolo : null,
        minuta: filtro.minuta ? filtro.minuta : null,
        origem: filtro.origem === "Todos" ? null : filtro.origem,
        status: filtro.status === "Todos" ? null : filtro.status,
        setor: setor ? setor.telefoneId : null,
        atendente: usuario,
        dataInicial: filtro.dataInicio
          ? filtro.dataInicio.format("DD/MM/yyyy")
          : moment().format("DD/MM/yyyy"),
        dataFinal: filtro.dataFinal
          ? filtro.dataFinal.format("DD/MM/yyyy")
          : moment().format("DD/MM/yyyy"),
      });

      const dados: {
        protocolos: Protocolo[];
        totalPaginas: number;
        totalItens: number;
      } = resposta.data;

      if (dados) {
        setResultadoBusca((prev) => ({
          ...prev,
          protocolos: dados.protocolos,
          totalPaginas: dados.totalPaginas,
          totalItens: dados.totalItens,
          loading: false,
        }));
      } else {
        setResultadoBusca((prev) => ({
          ...prev,
          protocolos: [],
          totalPaginas: 1,
          totalItens: 0,
          loading: false,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const nomesDosUsuarios = usuarios
    .map((usuario) => usuario.nome)
    .sort((a, b) => a.localeCompare(b));

  return (
    <ul className={styles.filtro}>
      <li>
        <div className={styles.periodo}>
          <span className={styles.span}>Periodo:</span>
          <DatePickerPeriodo
            value={[filtro.dataInicio, filtro.dataFinal]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({
                ...prev,
                dataInicio: valor ? valor[0] : moment(),
                dataFinal: valor ? valor[1] : moment(),
              }))
            }
          />
        </div>
      </li>
      <li>
        <div className={styles.itens}>
          <span className={styles.span}>Protocolo:</span>
          <input
            className={styles.input_protocolo}
            required={false}
            type="text"
            placeholder="Digite um protocolo"
            value={filtro.protocolo}
            onChange={(e) =>
              setFiltro((prev) => ({
                ...prev,
                protocolo: e.target.value,
              }))
            }
            onKeyDown={handleEnterKeyPress}
          />
        </div>
        <div className={styles.itens}>
          <span className={styles.span}>Minuta:</span>
          <input
            className={styles.input_protocolo}
            required={false}
            type="text"
            placeholder="Digite uma minuta"
            value={filtro.minuta}
            onChange={(e) =>
              setFiltro((prev) => ({
                ...prev,
                minuta: e.target.value,
              }))
            }
            onKeyDown={handleEnterKeyPress}
          />
        </div>
      </li>
      <li>
        <div className={styles.itens}>
          <span className={styles.span}>Status:</span>
          <SelectInput
            value={filtro.status}
            opcoes={["Todos", "ura", "espera", "atendimento", "finalizado"]}
            aoAlterado={(e) =>
              setFiltro((prev) => ({
                ...prev,
                status: e,
              }))
            }
          />
        </div>
        <div className={styles.itens}>
          <span className={styles.span}>Atendente:</span>
          <SelectInput
            value={filtro.atendente}
            opcoes={["Todos", "Bot", ...nomesDosUsuarios]}
            aoAlterado={(e) => setFiltro((prev) => ({ ...prev, atendente: e }))}
          />
        </div>
      </li>

      <li>
        <div className={styles.itens}>
          <span className={styles.span}>Origem:</span>
          <SelectInput
            value={filtro.origem}
            opcoes={["Todos", "ativo", "receptivo"]}
            aoAlterado={(e) =>
              setFiltro((prev) => ({
                ...prev,
                origem: e,
              }))
            }
          />
        </div>
        <div className={styles.itens}>
          <span className={styles.span}>Setor:</span>
          <SelectInput
            value={filtro.setor}
            opcoes={[
              "Todos",
              ...opcoesDepartamento.map((opcoes) => opcoes.nome),
            ]}
            aoAlterado={(e) =>
              setFiltro((prev) => ({
                ...prev,
                setor: e,
              }))
            }
          />
        </div>
      </li>

      <li>
        <Botao cor="primaria" onClick={() => buscar()}>
          Buscar
        </Botao>
      </li>
    </ul>
  );
}
