import { Mensagem } from "../../../../interfaces/IMensagem";
import { Contato } from "../../../../interfaces/IContato";
import { LuCheck, LuCheckCheck } from "react-icons/lu";
import styles from "./MensagemLocalicacao.module.css";

interface MensagemImagemProps {
  mensagem: Mensagem;
  contatoBot: Contato;
}

export default function MensagemLocalicacao({
  mensagem,
  contatoBot,
}: MensagemImagemProps) {
  const url = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s+ff0000(${mensagem.longitude},${mensagem.latitude})/${mensagem.longitude},${mensagem.latitude},15,20/300x250?access_token=pk.eyJ1IjoiaW5lY3RhciIsImEiOiJjbHUwN3h2amgwN2psMmttd3IxeXBmYThrIn0.xjntlek1xPx28_7q-r8d-w`;
  const enviadoBot =
    mensagem.destinatario !== contatoBot.telefone &&
    mensagem.destinatario !== contatoBot.telefone2;

  const horaFormatada = new Date(mensagem.criadoEm).toLocaleTimeString(
    "pt-BR",
    { hour: "2-digit", minute: "2-digit" }
  );

  return (
    <div
      className={`${styles.container} ${enviadoBot ? styles.remetente : ""}`}
    >
      <div className={`${styles.linha} ${enviadoBot ? styles.remetente : ""}`}>
        <div className={styles.conteudo}>
          <a
            className={styles.corpo}
            href={`https://www.google.com/maps/search/${mensagem.latitude},+${mensagem.longitude}`}
            target="_blank" rel="noreferrer"
          >
            <img src={url} />
          </a>
          <div className={styles.rodape}>
            <span>{horaFormatada}</span>
            {enviadoBot && (
              <>
                {mensagem.status === "delivered" ||
                mensagem.status === "read" ? (
                  <LuCheckCheck />
                ) : (
                  <LuCheck />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
