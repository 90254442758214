import styles from "./CardDashboard.module.css";

interface CardDashboardProps {
  nome: string;
  quantidade: number;
  cor: string;
}

export default function CardDashboard({
  nome,
  quantidade,
  cor,
}: CardDashboardProps) {
  const headerStyle = `${styles.header} ${cor ? styles[cor] : ""}`;

  return (
    <div className={styles.container}>
      <label className={headerStyle}>{nome}</label>
      <label className={styles.body}>{quantidade}</label>
    </div>
  );
}
