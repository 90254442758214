import styles from "./Monitoramento.module.css";
import { MdUpdate } from "react-icons/md";
import MonitoramentoCards from "./MonitoramentoCards";
import MonitoramentoEmAtendimento from "./MonitoramentoEmAtendimento";
import MonitoramentoEmEspera from "./MonitoramentoEmEspera";
import MonitoramentoAtendentes from "./MonitoramentoAtendentes";
import { useDashboard } from "../../../context/DashboardContext";
import { useEffect } from "react";
import { useSocket } from "../../../context/SocketContext";
import { useUsuario } from "../../../context/UsuarioContext";
import MonitoramentoGraficos from "./MonitoramentoGraficos";

export default function Monitoramento() {
  const {
    monitoramentoOpcoes,
    opcaoSelecionada,
    setOpcaoSelecionada,
    atualizar,
  } = useDashboard();
  const { online, entrarNaSala } = useSocket();
  const { usuario } = useUsuario();

  useEffect(() => {
    if (!online && usuario) {
      entrarNaSala(usuario._id);
    }
  }, [online]);

  return (
    <div className={styles.container}>
      <div className={styles.cabecalho}>
        <h2>Monitoramento</h2>
        <div className={styles.update} onClick={() => atualizar()}>
          <MdUpdate />
        </div>
      </div>
      <MonitoramentoCards />
      <div className={styles.conteudo}>
        <div>
          <div className={styles.modulos}>
            {monitoramentoOpcoes.map((opcao) => {
              return (
                <span
                  className={opcaoSelecionada === opcao ? styles.ativo : ""}
                  key={opcao}
                  onClick={() => setOpcaoSelecionada(opcao)}
                >
                  {opcao}
                </span>
              );
            })}
          </div>
          {opcaoSelecionada === "Em Atendimento" && (
            <MonitoramentoEmAtendimento />
          )}
          {opcaoSelecionada === "Em Espera" && <MonitoramentoEmEspera />}
          {opcaoSelecionada === "Atendentes" && <MonitoramentoAtendentes />}
          {opcaoSelecionada === "Gráficos" && <MonitoramentoGraficos />}
        </div>
      </div>
    </div>
  );
}
