import { Mensagem } from "../../../interfaces/IMensagem";
import { useMensagem } from "../../../context/MensagensContext";
import MensagemImagem from "./MensagemImagem";
import MensagemDocumento from "./MensagemDocumento";
import MensagemAudio from "./MensagemAudio";
import MensagemLocalicacao from "./MensagemLocalicacao";
import MensagemTexto from "./MensagemTexto";

interface MensagensProps {
  mensagens: Mensagem[] | undefined;
}

export default function Mensagens({ mensagens }: MensagensProps) {
  const { contatoBot } = useMensagem();
  return (
    <>
      {mensagens &&
        contatoBot &&
        mensagens.map(
          (msg) =>
            (msg.tipo === "image" && (
              <MensagemImagem
                key={msg._id}
                mensagem={msg}
                contatoBot={contatoBot}
              />
            )) ||
            (msg.tipo === "document" && (
              <MensagemDocumento
                key={msg._id}
                mensagem={msg}
                contatoBot={contatoBot}
              />
            )) ||
            (msg.tipo === "audio" && (
              <MensagemAudio
                key={msg._id}
                mensagem={msg}
                contatoBot={contatoBot}
              />
            )) ||
            (msg.tipo === "location" && (
              <MensagemLocalicacao
                key={msg._id}
                mensagem={msg}
                contatoBot={contatoBot}
              />
            )) || (
              <MensagemTexto
                key={msg._id}
                mensagem={msg}
                contatoBot={contatoBot}
              />
            )
        )}
    </>
  );
}
