import { DatePicker } from "antd";
import moment, { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";
import { ConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";

const MyDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = MyDatePicker;

interface props {
  value: [Moment | null, Moment | null];
  aoAlterado: (dates: [Moment | null, Moment | null]) => void;
}

export default function DatePickerPeriodo({ value, aoAlterado }: props) {
  return (
    <ConfigProvider locale={ptBR}>
      <RangePicker
        placeholder={["De", "Até"]}
        format={"DD/MM/yyyy"}
        value={value}
        onChange={(dates) =>
          aoAlterado(dates as [Moment | null, Moment | null])
        }
        presets={[
          {
            label: "Hoje",
            value: [moment(), moment()],
          },
          {
            label: "Ultimos 7 dias",
            value: [moment().subtract(7, "days"), moment()],
          },
          {
            label: "Este Mês",
            value: [moment().startOf("month"), moment().endOf("month")],
          },
          {
            label: "Ultimos 3 meses",
            value: [moment().subtract(3, "months"), moment()],
          },
        ]}
      />
    </ConfigProvider>
  );
}
