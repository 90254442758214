import { useEffect, useRef } from "react";
import styles from "./ConversasBody.module.css";
import { useMensagem } from "../../../../context/MensagensContext";
import Mensagens from "../../Mensagens";

export default function ConversasBody() {
  const listaRef = useRef<HTMLDivElement>(null);
  const { conversaAtual } = useMensagem();

  useEffect(() => {
    const container = listaRef.current;
    if (container && container.lastChild) {
      const lastChild = container.lastChild as HTMLElement;
      lastChild.scrollIntoView({ behavior: "auto" });
    }
  }, [conversaAtual]);

  return (
    <div className={styles.container}>
      <div ref={listaRef}>
        <Mensagens mensagens={conversaAtual} />
      </div>
    </div>
  );
}
