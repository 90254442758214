import { useEffect, useState } from "react";
import styles from "./InformativoHistoricoConversa.module.css";
import { IoMdClose } from "react-icons/io";
import moment from "moment";
import api from "../../../../utils/api";
import { Mensagem } from "../../../../interfaces/IMensagem";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import ModalHistoricoInfomacoes from "../../ModalHistoricoInfomacoes";
import Mensagens from "../../Mensagens";

interface InformativoHistoricoConversaProps {
  protocoloHistorico: Protocolo | undefined;
  setProtocoloHistorico: (protocolo: Protocolo | undefined) => void;
}

export default function InformativoHistoricoConversa({
  protocoloHistorico,
  setProtocoloHistorico,
}: InformativoHistoricoConversaProps) {
  const [conversa, setConversa] = useState<Mensagem[]>([]);
  const [abreModalInformacoes, setAbreModalInformacoes] = useState<boolean>();

  useEffect(() => {
    if (protocoloHistorico) buscaConversa();
  }, [protocoloHistorico]);

  const buscaConversa = async () => {
    if (protocoloHistorico) {
      const resposta = await api.get(
        `mensagem/${protocoloHistorico.protocolo}`
      );
      setConversa(resposta.data);
    }
  };

  const voltar = () => {
    setProtocoloHistorico(undefined);
  };

  const abreFechaModalInformacoes = () => {
    setAbreModalInformacoes(!abreModalInformacoes);
  };

  return (
    <div className={styles.container}>
      {abreModalInformacoes && protocoloHistorico && (
        <ModalHistoricoInfomacoes
          abreModal={abreModalInformacoes}
          abreFechaModal={abreFechaModalInformacoes}
          protocoloHistorico={protocoloHistorico}
        />
      )}
      <div className={styles.cabecalho}>
        <div className={styles.principal}>
          <div>
            <span>{protocoloHistorico?.protocolo}</span>
          </div>
          <div className={styles.voltar} onClick={() => voltar()}>
            <IoMdClose />
          </div>
        </div>
        <div className={styles.data}>
          <span>
            {moment(protocoloHistorico?.criadoEm).format("DD/MM/yyy")}
          </span>
        </div>
        <div className={styles.informacoes}>
          <span onClick={abreFechaModalInformacoes}>Informações</span>
        </div>
      </div>
      <div className={styles.conversa}>
        <Mensagens mensagens={conversa} />
      </div>
    </div>
  );
}
