import { Protocolo } from "../../../interfaces/IProtocolo";
import ConversasBody from "./ConversasBody";
import ConversasFooter from "./ConversasFooter";
import ConversasHeader from "./ConversasHeader";
import styles from "./Conversas.module.css";
import icone from "./conversa.png";

interface ConversasProps {
  protocoloSelecionado: Protocolo | undefined;
}

export default function Conversas({ protocoloSelecionado }: ConversasProps) {
  return (
    <>
      {protocoloSelecionado ? (
        <div className={styles.container}>
          <ConversasHeader />
          <ConversasBody />
          <ConversasFooter protocoloSelecionado={protocoloSelecionado} />
        </div>
      ) : (
        <div className={styles.sem_dados}>
          <img src={icone} alt="Pessoas conversando" />
          <p>Clique em "Chat" para buscar contatos em espera</p>
        </div>
      )}
    </>
  );
}
