import styles from "./InformativoInformacoes.module.css";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import { Minuta } from "../../../../interfaces/IMinuta";

interface InformativoInformacoesProps {
  protocoloSelecionado: Protocolo;
  minuta: Minuta | undefined;
}

export default function InformativoInformacoes({
  protocoloSelecionado,
  minuta,
}: InformativoInformacoesProps) {
  return (
    <div className={styles.card}>
      <ul className={styles.lista}>
        <li>
          <span>Protocolo: </span> {protocoloSelecionado.protocolo}
        </li>
        <li>
          <span>Origem: </span> {protocoloSelecionado.origem}
        </li>
      </ul>

      <div className={styles.pipe}></div>

      <ul className={styles.lista}>
        <li>
          <span>Tel: </span> {protocoloSelecionado.de.telefone}
        </li>
        <li>
          <span>CPF/CNPJ: </span>
          {protocoloSelecionado.de?.cpfCnpj
            ? protocoloSelecionado.de?.cpfCnpj
            : " - "}
        </li>
        <li className={styles.endereco}>
          <span>Endereço: </span>
          {protocoloSelecionado.de.endereco?.rua && (
            <li>{protocoloSelecionado.de.endereco?.rua}</li>
          )}
          {protocoloSelecionado.de.endereco?.bairro && (
            <li>{protocoloSelecionado.de.endereco?.bairro}</li>
          )}
          {protocoloSelecionado.de.endereco?.cidade &&
            protocoloSelecionado.de.endereco?.estado && (
              <li>
                {protocoloSelecionado.de.endereco?.cidade}
                {""}
                {protocoloSelecionado.de.endereco?.estado}
              </li>
            )}
          {protocoloSelecionado.de.endereco?.cep && (
            <li>{protocoloSelecionado.de.endereco?.cep}</li>
          )}
        </li>
      </ul>

      <div className={styles.pipe}></div>

      <ul className={styles.lista}>
        <li>
          <span>Minuta: </span>
          {protocoloSelecionado?.minuta ? protocoloSelecionado?.minuta : " - "}
        </li>
        <li>
          <span>Produto: </span>
          {minuta?.descricaoProduto ? minuta?.descricaoProduto : " - "}
        </li>
        <li>
          <span>NFe: </span>
          {minuta?.chaveNfe && minuta?.chaveNfe.length >= 44
            ? minuta?.chaveNfe.substring(25, 34)
            : " - "}
        </li>
        <li>
          <span>Motivo: </span>
          {minuta?.checklist?.motivo ? minuta?.checklist?.motivo : " - "}
        </li>
        <li>
          <span>Detalhes: </span>
          {minuta?.checklist?.detalhes ? minuta?.checklist?.detalhes : " - "}
        </li>
      </ul>
    </div>
  );
}
