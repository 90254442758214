import { FormEvent, useState } from "react";
import styles from "./HistoricoConversa.module.css";
import FileSaver from "file-saver";
import api from "../../../../utils/api";
import Loading from "../../../Shared/Loading";
import CampoInput from "../../../Shared/CampoInput";
import Botao from "../../../Shared/Botao";

export default function HistoricoConversa() {
  const [protocolo, setProtocolo] = useState("");
  const [loading, setLoading] = useState(false);

  const buscaRelatorio = async (e: FormEvent) => {
    e.preventDefault();

    setLoading(true);
    await api
      .get(`/relatorio/historico/${protocolo}`, {
        responseType: "blob",
        timeout: 60000,
      })
      .then((resposta) => {
        const blob = new Blob([resposta.data], { type: "application/pdf" });
        FileSaver.saveAs(blob, `relatorio-${protocolo}.pdf`);
      })
      .catch(() => {
        alert("Protocolo não encontrado");
      });

    setLoading(false);
  };

  return (
    <div className={styles.card}>
      {loading ? (
        <Loading />
      ) : (
        <form className={styles.conteudo} onSubmit={buscaRelatorio}>
          <span>Histórico da Conversa</span>
          <CampoInput
            required={true}
            type="text"
            placeholder="Digite o protocolo"
            value={protocolo}
            aoAlterado={(e) => setProtocolo(e)}
          ></CampoInput>
          <Botao cor="primaria" onClick={buscaRelatorio}>
            Gerar
          </Botao>
        </form>
      )}
    </div>
  );
}
