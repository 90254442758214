import Modal from "react-modal";
import styles from "./ModalAtendimentos.module.css";
import api from "../../../../utils/api";
import { IoMdClose } from "react-icons/io";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import { useEffect, useState } from "react";
import { Mensagem } from "../../../../interfaces/IMensagem";
import { useMensagem } from "../../../../context/MensagensContext";
import moment from "moment";
import { Minuta } from "../../../../interfaces/IMinuta";
import Botao from "../../../Shared/Botao";
import Loading from "../../../Shared/Loading";
import FileSaver from "file-saver";
import Mensagens from "../../../Chat/Mensagens";
import { useUsuario } from "../../../../context/UsuarioContext";
import BotaoChat from "../../../Chat/BotaoChat";
import { BiTransferAlt } from "react-icons/bi";
import ModalTransferir from "../../../Chat/ModalTransferir";
import { useProtocolo } from "../../../../context/ProtocoloContext";

interface ModalAtendimentosProps {
  abreModal: boolean;
  abreFechaModal: () => void;
  protocoloSelecionado: Protocolo | undefined;
}

export default function ModalAtendimentos({
  abreModal,
  abreFechaModal,
  protocoloSelecionado,
}: ModalAtendimentosProps) {
  const { usuario, usuarios } = useUsuario();
  const [conversa, setConversa] = useState<Mensagem[]>([]);
  const [minuta, setMinuta] = useState<Minuta>();
  const [loading, setLoading] = useState(false);
  const { contatoBot, buscaContatoBot, apagaMensagensCache } = useMensagem();
  const { setProtocoloSelecionado, atualizaEsperaAtendimento } = useProtocolo();

  const [abreModalTranferir, setAbreModalTranferir] = useState<boolean>(false);

  const abrirFecharModalTransferir = () => {
    setAbreModalTranferir(!abreModalTranferir);
  };

  const voltar = () => {
    abreFechaModal();
  };

  useEffect(() => {
    if (!contatoBot) buscaContatoBot("SAC");
    if (protocoloSelecionado) {
      buscaConversa();
      consultaMinuta();
    }
  }, [protocoloSelecionado, contatoBot]);

  const buscaConversa = async () => {
    if (protocoloSelecionado) {
      const resposta = await api.get(
        `mensagem/${protocoloSelecionado.protocolo}`
      );
      setConversa(resposta.data);
    }
  };

  const consultaMinuta = async () => {
    try {
      if (protocoloSelecionado) {
        const resposta = await api.get(
          `/minuta/numero/${protocoloSelecionado.minuta}`
        );
        const dados: Minuta = resposta.data;

        if (dados) {
          setMinuta(dados);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const selecionaAtendente = (id?: string) => {
    try {
      if (id) {
        const usuario = usuarios.find((user) => user._id === id);
        return usuario?.nome || " ";
      } else {
        return "TsChatBot";
      }
    } catch (error) {
      console.log(error);
      return "Erro";
    }
  };

  const buscaRelatorio = async () => {
    setLoading(true);
    await api
      .get(`/relatorio/historico/${protocoloSelecionado?.protocolo}`, {
        responseType: "blob",
        timeout: 60000,
      })
      .then((resposta) => {
        const blob = new Blob([resposta.data], { type: "application/pdf" });
        FileSaver.saveAs(
          blob,
          `relatorio-${protocoloSelecionado?.protocolo}.pdf`
        );
      })
      .catch(() => {
        alert("Protocolo não encontrado");
      });

    setLoading(false);
  };

  return (
    <>
      {abreModalTranferir && protocoloSelecionado && (
        <ModalTransferir
          abrirFecharModalTransferir={abrirFecharModalTransferir}
          abreModalTranferir={abreModalTranferir}
          protocoloSelecionado={protocoloSelecionado}
          setProtocoloSelecionado={setProtocoloSelecionado}
          atualizaEsperaAtendimento={atualizaEsperaAtendimento}
          apagaMensagensCache={apagaMensagensCache}
          usuario={usuario}
        />
      )}
      <Modal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={abreModal}
        onRequestClose={voltar}
        ariaHideApp={false}
      >
        <div className={styles.card}>
          <div className={styles.cabecalho}>
            <div>
              <span>Protocolo: {protocoloSelecionado?.protocolo}</span>
            </div>
            <div className={styles.voltar} onClick={() => voltar()}>
              <IoMdClose />
            </div>
          </div>
          <div className={styles.conteudo}>
            <div className={styles.informacoes}>
              <table>
                {usuario && usuario.acesso === "Adm" && (
                  <tr className={styles.transferir}>
                    <BotaoChat
                      nome="Transferir"
                      onClick={() => abrirFecharModalTransferir()}
                    >
                      <BiTransferAlt />
                    </BotaoChat>
                  </tr>
                )}
                <tr>
                  <td className={styles.titulo}>Data Abertura:</td>
                  <td>
                    {moment(protocoloSelecionado?.criadoEm).format(
                      "DD/MM/yyyy"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Data Alteração:</td>
                  <td>
                    {moment(protocoloSelecionado?.atualizadoEm).format(
                      "DD/MM/yyyy"
                    )}
                  </td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Atendente:</td>
                  <td>
                    {protocoloSelecionado?.atendente
                      ? selecionaAtendente(protocoloSelecionado.atendente)
                      : "TsChatbot"}
                  </td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Origem:</td>
                  <td>{protocoloSelecionado?.origem}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Nome:</td>
                  <td>{protocoloSelecionado?.de.nome}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Telefone:</td>
                  <td>{protocoloSelecionado?.de.telefone}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Minuta:</td>
                  <td>{protocoloSelecionado?.minuta}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Embarcador:</td>
                  <td>{minuta?.embarcador?.nome}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Produto:</td>
                  <td>{minuta?.descricaoProduto}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>ChaveNfe:</td>
                  <td>{minuta?.chaveNfe}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Motivo:</td>
                  <td>{minuta?.checklist?.motivo}</td>
                </tr>
                <tr>
                  <td className={styles.titulo}>Detalhes:</td>
                  <td>{minuta?.checklist?.detalhes}</td>
                </tr>
              </table>
              {loading ? (
                <Loading />
              ) : (
                <div className={styles.gerar}>
                  <Botao cor="primaria" onClick={buscaRelatorio}>
                    Gerar Arquivo
                  </Botao>
                </div>
              )}
            </div>
            <div className={styles.conversa}>
              <Mensagens mensagens={conversa} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
