import { FormEvent, useState } from "react";
import styles from "./InformativoAgendar.module.css";
import moment, { Moment } from "moment";
import Botao from "../../../Shared/Botao";
import { Minuta } from "../../../../interfaces/IMinuta";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import api from "../../../../utils/api";
import DatePickerUnico from "../../../Shared/DatePickerUnico";
import SelectInput from "../../../Shared/SelectInput";

interface InformativoAgendarProps {
  minuta: Minuta | undefined;
  protocoloSelecionado: Protocolo;
}

export default function InformativoAgendar({
  minuta,
  protocoloSelecionado,
}: InformativoAgendarProps) {
  const [ocorrencia, setOcorrencia] = useState<string>("Agendado");
  const [dataAgenda, setDataAgenda] = useState<Moment>(moment().add(1, 'day'));
  const [comentario, setComentario] = useState<string>("");
  const ocorrencias: string[] = [
    "Agendado",
    "Agendamento Dedicado",
    "Cliente Recusou",
    "Já devolveu",
    "Produto inadequado",
    "Vai ficar com o produto",

  ];

  const opcoesOcorrencia: { [key: string]: number } = {
    "Agendado": 300,
    "Agendamento Dedicado": 114,
    "Cliente Recusou": 303,
    "Já devolveu": 304,
    "Produto inadequado": 305,
    "Vai ficar com o produto": 306,
  };

  const enviaOcorrencia = async (e: FormEvent) => {
    e.preventDefault();
    const ocorrenciaValue = opcoesOcorrencia[ocorrencia]; // Use 0 como valor padrão se não encontrar a opção
    try {
      if (!minuta) {
        alert("Protocolo sem minuta para realizar agendamento");
        return;
      }

      if (!comentario) {
        alert("Campo Comentario não pode estar vazio");
        return;
      }

      const resposta = await api.post("/minuta/agendar", {
        ocorrencia: ocorrenciaValue,
        dataAgendamento: dataAgenda.format("DD/MM/yyyy"),
        chaveNfe: minuta?.chaveNfe,
        comentario: comentario ? comentario : `Sem Comentario`,
        protocolo: protocoloSelecionado.protocolo,
      });

      const dados = resposta.data;

      alert(`${dados.message}`);
      setOcorrencia("Agendado");
      setComentario("");

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error.response?.data || error.message);
      alert("Erro ao agendar");
    }
  };

  return (
    <div className={styles.card}>
      <h3>Adicionar Ocorrencia</h3>
      <form onSubmit={enviaOcorrencia} className={styles.ocorrencias}>
        <div className={styles.corpo}>
          <DatePickerUnico aoAlterado={setDataAgenda} value={dataAgenda} />
          <SelectInput
            value={ocorrencia}
            opcoes={ocorrencias}
            aoAlterado={(e) => setOcorrencia(e)}
            placeholder="Ocorrência"
          />
        </div>
        <textarea
          value={comentario}
          placeholder="Digite seu comentario"
          onChange={(e) => setComentario(e.target.value)}
        />
        <Botao cor="primaria" onClick={enviaOcorrencia}>
          Enviar
        </Botao>
      </form>
    </div>
  );
}
