import axios, { AxiosInstance } from "axios";

// Homologacao
// const API_URL = "https://chatbot.devinectar.com.br"; // Hostinger
// const API_URL = `http://localhost:3333`;

// Producao
const API_URL = "https://chatbot.inectar.com.br"; // Hostinger

const api: AxiosInstance = axios.create({
  baseURL: `${API_URL}/api`, // URL base da sua API
});

api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      sessionStorage.removeItem("token");
      window.location.reload();
    } else {
      console.log(error);
    }
    return Promise.reject(error);
  }
);

export { API_URL };
export default api;
