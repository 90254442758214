import PlanilhaBot from "./PlanilhaBot";
import EnviaAtivo from "./EnviaAtivo";
import Report from "./Report";
import styles from "./PreRotas.module.css";
import EnvioUnico from "./EnvioUnico";

export default function PreRotas() {
  return (
    <div className={styles.container}>
      <h2>Pré Rota</h2>
      <PlanilhaBot />
      <EnviaAtivo />
      <EnvioUnico />
      <h2>Report</h2>
      <Report />
    </div>
  );
}
