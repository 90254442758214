import styles from "./Navbar.module.css";
import logo from "./Logo iNectar Branca.svg";

import NavbarLink from "../NavbarLink";
import { useUsuario } from "../../../context/UsuarioContext";
import { useSocket } from "../../../context/SocketContext";
import Select from "../Select";
import { useDepartamento } from "../../../context/DepartamentoContext";
import { Icon } from "@iconify-icon/react";

export default function Navbar() {
  const { online, entrarNaSala, sairDaSala } = useSocket();
  const { usuario } = useUsuario();
  const { departamento, setDepartamento, opcoesDepartamento } =
    useDepartamento();

  const logarSocket = () => {
    if (!online && usuario) {
      entrarNaSala(usuario?._id);
    } else {
      sairDaSala();
    }
  };

  return (
    <header>
      <div className={styles.container}>
        <div className={styles.cabecalho}>
          <div className={styles.lado_esquerdo}>
            <div className={styles.logo}>
              <img src={logo} alt="logo da Inectar" />
            </div>
            <Select
              required={true}
              value={departamento.nome}
              aoAlterado={(e) => {
                const departamentoSelecionado = opcoesDepartamento.find(
                  (opcao) => opcao.nome === e
                );
                if (departamentoSelecionado) {
                  setDepartamento(departamentoSelecionado);
                }
              }}
              opcoes={opcoesDepartamento.map((opcao) => opcao.nome)}
            ></Select>
          </div>

          <nav className={styles.links}>
            <NavbarLink url={"/chat"}>Chat</NavbarLink>
            <NavbarLink url={"/painelControle"}>Painel de Controle</NavbarLink>
            <div className={styles.pipe}></div>
            <div className={styles.nome_usuario}>
              <span>{usuario ? usuario.nome : ""}</span>
            </div>
            <div className={styles.usuario}>
              <Icon
                className={styles.avatar}
                icon="carbon:user-avatar-filled"
                onClick={() => logarSocket()}
              />
              {online ? (
                <div className={styles.online} />
              ) : (
                <div className={styles.offline} />
              )}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}
