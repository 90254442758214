import styles from "./SidebarHeader.module.css";
import BotaoChat from "../../BotaoChat";
import { useProtocolo } from "../../../../context/ProtocoloContext";
import { useSocket } from "../../../../context/SocketContext";
import { useDepartamento } from "../../../../context/DepartamentoContext";
import { Icon } from "@iconify-icon/react";

export default function SidebarHeader() {
  const {
    emEsperaParaAtendimento,
    atualizaEsperaAtendimento,
    aguardando,
    aguardandoMotorista,
  } = useProtocolo();

  const { departamento } = useDepartamento();
  const { online } = useSocket();

  const puxaContatoAtendimento = () => {
    if (online) {
      emEsperaParaAtendimento();
    } else {
      alert("Fique online para puxar um contato");
    }
  };

  return (
    <div className={styles.container}>
      <h3>Aguardando atendimento</h3>
      {departamento.nome === "Motoristas" ? (
        <span>{aguardandoMotorista}</span>
      ) : (
        <span>{aguardando}</span>
      )}
      <div className={styles.pipe}></div>
      <div className={styles.botoes}>
        <BotaoChat nome={"Chat"} onClick={puxaContatoAtendimento}>
          <Icon icon="bi:person-down" />
        </BotaoChat>
        <BotaoChat nome={"Atualizar"} onClick={atualizaEsperaAtendimento}>
          <Icon icon="ion:reload-outline" />
        </BotaoChat>
      </div>
    </div>
  );
}
