import { useState } from "react";
import DatePickerPeriodo from "../../../Shared/DatePickerPeriodo";
import styles from "./RelatorioTempoAtendimento.module.css";
import moment, { Moment } from "moment";
import Botao from "../../../Shared/Botao";
import { useUsuario } from "../../../../context/UsuarioContext";
import api from "../../../../utils/api";

interface filtro {
  dataInicial: Moment | null;
  dataFinal: Moment | null;
}

export default function RelatorioTempoAtendimento() {
  const { usuario } = useUsuario();
  const [filtro, setFiltro] = useState<filtro>({
    dataInicial: moment().subtract(1, "days"),
    dataFinal: moment(),
  });

  const gerarRelatorio = async () => {
    try {
      const resposta = await api.post("relatorio/tempo-atendimento", {
        dataInicio: filtro.dataInicial?.format("DD/MM/yyyy"),
        dataFinal: filtro.dataFinal?.format("DD/MM/yyyy"),
        email: usuario?.email,
      });

      const dados = resposta.data;
      if (dados) alert("Gerando Planilha, em breve voce recebera em seu email");
    } catch (error) {
      console.error(error);
      alert("Erro ao gerar planilha");
    }
  };

  return (
    <div className={styles.card}>
      <h3>Tempo de Atendimento</h3>
      <div className={styles.conteudo}>
        <div className={styles.periodo}>
          <label>Periodo</label>
          <DatePickerPeriodo
            value={[filtro.dataInicial, filtro.dataFinal]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({
                ...prev,
                dataInicial: valor ? valor[0] : moment(),
                dataFinal: valor ? valor[1] : moment(),
              }))
            }
          />
        </div>

        <Botao cor="primaria" onClick={() => gerarRelatorio()}>
          Gerar
        </Botao>
      </div>
    </div>
  );
}
