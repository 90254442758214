import { FormEvent, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./AlterarSenha.module.css";
import CampoInput from "../../Shared/CampoInput";
import Botao from "../../Shared/Botao";
import api from "../../../utils/api";

export default function AlterarSenha() {
  const { token } = useParams();
  const [novaSenha, setNovaSenha] = useState("");
  const [novaSenhaConfirm, setNovaSenhaConfirm] = useState("");

  const enviaNovaSenha = async (e: FormEvent) => {
    e.preventDefault();
    if (novaSenha === novaSenhaConfirm) {
      api
        .post(
          `/usuario/alterarsenha`,
          {
            novaSenha,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((resposta) => {
          alert(resposta.data.message);
        })
        .catch((error) => {
          alert(error.response.data.message);
        });
    } else {
      alert("As senhas não conferem");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.formulario}>
        <form onSubmit={enviaNovaSenha}>
          <div className={styles.cartao__header}>
            <h2>Alteração de senha</h2>
          </div>
          <div className={styles.cartao__body}>
            <div>
              <CampoInput
                required={true}
                type="password"
                placeholder="Digite uma nova Senha"
                value={novaSenha}
                aoAlterado={(value) => setNovaSenha(value)}
              />
            </div>
            <div>
              <CampoInput
                required={true}
                type="password"
                placeholder="Confirme sua Senha"
                value={novaSenhaConfirm}
                aoAlterado={(value) => setNovaSenhaConfirm(value)}
              />
            </div>
          </div>
          <div className={styles.cartao__footer}>
            <Botao onClick={enviaNovaSenha} cor={"primaria"}>
              Enviar
            </Botao>
          </div>
        </form>
      </div>
    </div>
  );
}
