import styles from "./InformativoBody.module.css";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import InformativoAgendar from "../InformativoAgendar";
import InformativoInformacoes from "../InformativoInformacoes";
import api from "../../../../utils/api";
import { useEffect, useState } from "react";
import { Minuta } from "../../../../interfaces/IMinuta";

interface InformativoBodyProps {
  protocoloSelecionado: Protocolo;
}

export default function InformativoBody({
  protocoloSelecionado,
}: InformativoBodyProps) {
  const [minuta, setMinuta] = useState<Minuta>();

  useEffect(() => {
    if (protocoloSelecionado) buscaMinuta();
  }, [protocoloSelecionado]);

  const buscaMinuta = async () => {
    try {
      setMinuta(undefined);
      if (protocoloSelecionado.minuta) {
        const resposta = await api.get(
          `/minuta/numero/${protocoloSelecionado.minuta}`
        );
        const dados = resposta.data;
        if (dados) setMinuta(dados);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <InformativoInformacoes
        protocoloSelecionado={protocoloSelecionado}
        minuta={minuta}
      />

      <InformativoAgendar
        minuta={minuta}
        protocoloSelecionado={protocoloSelecionado}
      />
    </div>
  );
}
