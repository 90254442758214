import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

interface GraficoBarrasProps {
  quantidadePorDia: { data: string; quantidade: number }[];
}

export default function GraficoBarras({
  quantidadePorDia,
}: GraficoBarrasProps) {
  const [chartData, setChartData] = useState<{
    series: { name: string; data: number[] }[];
    options: {
      chart: { width: number; type: "bar" };
      plotOptions: {
        bar: { horizontal: boolean; columnWidth: string; endingShape: string };
      };
      dataLabels: { enabled: boolean };
      stroke: { show: boolean; width: number; colors: string[] };
      xaxis: { categories: string[] };
      yaxis: { title: { text: string } };
      fill: { opacity: number };
      tooltip: { y: { formatter: (val: number) => string } };
    };
  }>({
    series: [
      {
        name: "Quantidade por Dia",
        data: [],
      },
    ],
    options: {
      chart: {
        width: 380,
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "Atendimentos",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: (val: number) => val.toString(),
        },
      },
    },
  });

  useEffect(() => {
    // Atualiza os dados do gráfico com base no estado quantidadePorDia
    setChartData((prevChartData) => ({
      ...prevChartData,
      series: [
        {
          name: "Atendimentos por Dia",
          data: quantidadePorDia.map((entry) => entry.quantidade),
        },
      ],
      options: {
        ...prevChartData.options,
        xaxis: {
          categories: quantidadePorDia.map((entry) => entry.data),
        },
      },
    }));
  }, [quantidadePorDia]);

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        width={580}
      />
    </div>
  );
}
