import styles from "./InformativoInformacoesMotorista.module.css";
import Botao from "../../../Shared/Botao";
import { useOcorrencia } from "../../../../context/OcorrenciaContext";
import { Icon } from "@iconify-icon/react";
import { Protocolo } from "../../../../interfaces/IProtocolo";

interface props {
  protocoloSelecionado: Protocolo;
}

export default function InformativoOcorrenciaMotorista({
  protocoloSelecionado,
}: props) {
  const { evidencias, enviarEvidencia, removerEvidencia } = useOcorrencia();

  return (
    <div className={styles.card}>
      <h3>Evidência Motorista</h3>
      <div className={styles.conteudo}>
        {evidencias.length > 0 ? (
          <div className={styles.evidencias}>
            {evidencias.map((evidencia, index) => {
              return (
                <div key={index} className={styles.evidencia}>
                  <img src={evidencia} />
                  <Icon
                    icon="solar:close-circle-bold"
                    className={styles.icone}
                    onClick={() => removerEvidencia(index)}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <p>Sem dados</p>
        )}
        <Botao
          cor="primaria"
          onClick={() => enviarEvidencia(protocoloSelecionado.minuta || " ")}
        >
          Enviar
        </Botao>
      </div>
    </div>
  );
}
