import Modal from "react-modal";
import styles from "./ModalAdicionarUsuario.module.css";
import { IoMdClose } from "react-icons/io";
import CampoInput from "../../../Shared/CampoInput";
import { useState } from "react";
import Botao from "../../../Shared/Botao";
import Select from "../../../Shared/Select";
import api from "../../../../utils/api";

interface ModalAdicionarUsuarioProps {
  abreModal: boolean;
  abreFechaModal: () => void;
}

export default function ModalAdicionarUsuario({
  abreModal,
  abreFechaModal,
}: ModalAdicionarUsuarioProps) {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmaSenha, setConfirmaSenha] = useState("");
  const [acesso, setAcesso] = useState("");
  const [erros, setErros] = useState<string[]>([]);
  const opcoes = ["", "Adm", "Funcionario"];

  const voltar = () => {
    abreFechaModal();
  };

  const cadastrar = async () => {
    if (validarFormulario()) {
      try {
        const resposta = await api.post("usuario/criar", {
          nome,
          email,
          senha,
          confirmaSenha,
          acesso,
        });

        const dados = resposta.data;

        if (dados) {
          alert(dados.message);
          abreFechaModal();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const validarFormulario = () => {
    const novosErros: string[] = [];

    if (!nome) novosErros.push("O campo nome é obrigatório.");
    if (!email) novosErros.push("O campo email é obrigatório.");
    if (!senha) novosErros.push("O campo senha é obrigatório.");
    if (!confirmaSenha)
      novosErros.push("O campo confirmar senha é obrigatório.");
    if (!acesso) novosErros.push("Selecione um item de acesso.");

    if (senha !== confirmaSenha) novosErros.push("As senhas não conferem.");

    setErros(novosErros);
    return novosErros.length === 0;
  };

  return (
    <>
      <Modal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={abreModal}
        ariaHideApp={false}
      >
        <div className={styles.card}>
          <div className={styles.cabecalho}>
            <div>
              <span>Adicionar Novo Usuario</span>
            </div>
            <div className={styles.voltar} onClick={() => voltar()}>
              <IoMdClose />
            </div>
          </div>
          <form className={styles.corpo}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <span>Nome:</span>
                  </td>
                  <td>
                    <CampoInput
                      required={true}
                      type={"text"}
                      placeholder=""
                      value={nome}
                      aoAlterado={(e) => setNome(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Email:</span>
                  </td>
                  <td>
                    <CampoInput
                      required={true}
                      type={"email"}
                      placeholder=""
                      value={email}
                      aoAlterado={(e) => setEmail(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Senha:</span>
                  </td>
                  <td>
                    <CampoInput
                      required={true}
                      type={"password"}
                      placeholder=""
                      value={senha}
                      aoAlterado={(e) => setSenha(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Confimar Senha:</span>
                  </td>
                  <td>
                    <CampoInput
                      required={true}
                      type={"password"}
                      placeholder=""
                      value={confirmaSenha}
                      aoAlterado={(e) => setConfirmaSenha(e)}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Acesso:</span>
                  </td>
                  <td>
                    <Select
                      required={true}
                      value={acesso}
                      opcoes={opcoes}
                      aoAlterado={(e) => setAcesso(e)}
                      limitaLargura={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>

            {erros.length > 0 && (
              <div className={styles.erro}>
                {erros.map((erro, index) => (
                  <p key={index}>{erro}</p>
                ))}
              </div>
            )}

            <div className={styles.rodape}>
              <Botao cor={"primaria"} onClick={() => cadastrar()}>
                Enviar
              </Botao>
              <Botao cor={"vermelho"} onClick={() => voltar()}>
                Cancelar
              </Botao>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}
