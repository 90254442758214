import React from "react";
import { Mensagem } from "../../../../interfaces/IMensagem";
import { Contato } from "../../../../interfaces/IContato";
import { LuCheckCheck, LuCheck } from "react-icons/lu";
import styles from "./MensagemTexto.module.css";

interface MensagemTextoProps {
  mensagem: Mensagem;
  contatoBot: Contato;
}

export default function MensagemTexto({
  mensagem,
  contatoBot,
}: MensagemTextoProps) {
  const enviadoBot =
    mensagem.destinatario !== contatoBot.telefone &&
    mensagem.destinatario !== contatoBot.telefone2;
  const horaFormatada = new Date(mensagem.criadoEm).toLocaleTimeString(
    "pt-BR",
    { hour: "2-digit", minute: "2-digit" }
  );

  const formatarTexto = (texto: string) => {
    const textoFormatado = texto.split("\n").map((linha, linhaIndex) => (
      <React.Fragment key={linhaIndex}>
        {linha.split("*").map((parte, index) => {
          if (index % 2 === 1) {
            return (
              <span className={styles.negrito} key={index}>
                {parte}
              </span>
            );
          }
          return parte;
        })}
        <br />
      </React.Fragment>
    ));

    return textoFormatado;
  };

  return (
    <div
      className={`${styles.container} ${enviadoBot ? styles.remetente : ""}`}
    >
      <div className={`${styles.linha} ${enviadoBot ? styles.remetente : ""}`}>
        <div className={`${styles.conteudo}`}>
          <div className={styles.texto}>
            <span>{formatarTexto(mensagem.texto)}</span>
          </div>
          <div className={styles.rodape}>
            <span>{horaFormatada}</span>
            {enviadoBot && (
              <>
                {mensagem.status === "delivered" ||
                mensagem.status === "read" ? (
                  <LuCheckCheck />
                ) : (
                  <LuCheck />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
