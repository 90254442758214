import { Link } from "react-router-dom";
import styles from "./NavbarLink.module.css";

interface Props {
    url: string, 
    children: string
}

export default function NavbarLink({ url, children }: Props) {
    return(
        <Link to={url} className={styles.link}>
            {children}
        </Link>
    )
}