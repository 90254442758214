import { useEffect, useState } from "react";
import { Contato } from "../../../../interfaces/IContato";
import { Mensagem } from "../../../../interfaces/IMensagem";
import { LuCheckCheck, LuCheck } from "react-icons/lu";
import styles from "./MensagemAudio.module.css";
import ReactPlayer from "react-player";
import api from "../../../../utils/api";
import Loading from "../../../Shared/Loading";

interface MensagemAudioProps {
  mensagem: Mensagem;
  contatoBot: Contato;
}

export default function MensagemAudio({
  mensagem,
  contatoBot,
}: MensagemAudioProps) {
  const [audioCarregado, setAudioCarregado] = useState(false);
  const linkAudio = `https://chatbot.inectar.com.br/conversas/audios/${mensagem.texto}`;
  const enviadoBot =
    mensagem.destinatario !== contatoBot.telefone &&
    mensagem.destinatario !== contatoBot.telefone2;
  const horaFormatada = new Date(mensagem.criadoEm).toLocaleTimeString(
    "pt-BR",
    { hour: "2-digit", minute: "2-digit" }
  );

  const downloadMeta = async () => {
    try {
      const resposta = await api.get(`/mensagem/download/${mensagem._id}`);
      const dados = resposta.data;
      if (dados) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setAudioCarregado(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    downloadMeta();
  }, [linkAudio]);

  return (
    <div
      className={`${styles.container} ${enviadoBot ? styles.remetente : ""}`}
    >
      <div className={`${styles.linha} ${enviadoBot ? styles.remetente : ""}`}>
        <div className={`${styles.conteudo}`}>
          <div className={styles.corpo}>
            {audioCarregado ? (
              <div className={styles.audio_player}>
                <ReactPlayer
                  url={linkAudio}
                  width="100%"
                  height="30px"
                  controls
                />
              </div>
            ) : (
              <div className={styles.sem_audio}>
                <Loading />
              </div>
            )}
          </div>
          <div className={styles.rodape}>
            <span>{horaFormatada}</span>
            {enviadoBot && (
              <>
                {mensagem.status === "delivered" ||
                mensagem.status === "read" ? (
                  <LuCheckCheck />
                ) : (
                  <LuCheck />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
