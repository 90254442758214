import SidebarBody from "./SidebarBody";
import SidebarHeader from "./SidebarHeader";
import styles from "./Sidebar.module.css";

export default function Sidebar() {
  return (
    <div className={styles.container}>
      <SidebarHeader />
      <SidebarBody />
    </div>
  );
}
