import MonitoramentoGraficoProtocolo from "./MonitoramentoGraficoProtocolos";
import MonitoramentoGraficoTempoMedio from "./MonitoramentoGraficoTempoMedio";
import styles from "./MonitoramentoGraficos.module.css";
export default function MonitoramentoGraficos() {
  return (
    <div className={styles.container}>
      <h3>Protocolos</h3>
      <MonitoramentoGraficoProtocolo />
      <h3>Tempo Médio</h3>
      <MonitoramentoGraficoTempoMedio />
    </div>
  );
}
