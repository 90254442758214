import Modal from "react-modal";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./RecuperarSenha.module.css";
import api from "../../../utils/api";
import CampoInput from "../../Shared/CampoInput";
import Botao from "../../Shared/Botao";

interface RecuperarSenhaProps {
  isOpen: boolean;
  abrirFecharModal: () => void;
}

export default function RecuperarSenha({
  isOpen,
  abrirFecharModal,
}: RecuperarSenhaProps) {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const enviaRecuperarSenha = async (e: FormEvent) => {
    e.preventDefault();
    api
      .get(`/usuario/esquecisenha/${email}`)
      .then((resposta) => {
        alert(resposta.data.message);
        navigate("/");
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        isOpen={isOpen}
        ariaHideApp={false}
        overlayClassName={styles.overlay}
      >
        <form className={styles.formulario} onSubmit={enviaRecuperarSenha}>
          <div className={styles.header}>
            <h2>Recuperar Senha</h2>
          </div>
          <div className={styles.body}>
            <CampoInput
              required={true}
              type="text"
              placeholder="Digite seu Email"
              value={email}
              aoAlterado={(value) => setEmail(value)}
            />
          </div>
          <div className={styles.footer}>
            <Botao cor={"primaria"} onClick={enviaRecuperarSenha}>
              Enviar
            </Botao>
            <Botao cor={"vermelho"} onClick={abrirFecharModal}>
              Cancelar
            </Botao>
          </div>
        </form>
      </Modal>
    </div>
  );
}
