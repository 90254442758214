import React, { ChangeEvent, useRef, useState } from "react";
import { MdCloudUpload } from "react-icons/md";
import styles from "./EnviaAtivo.module.css";
import api from "../../../../utils/api";
import Botao from "../../../Shared/Botao";

const EnviaAtivo: React.FC = () => {
  const [arquivo, setArquivo] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const formatarNomeArquivo = (nome: string) => {
    const nomeArquivoFormatado =
      nome.length > 20 ? nome.substring(0, 20) + "..." : nome;

    return nomeArquivoFormatado;
  };

  const uploadArquivo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setArquivo(e.target.files[0]);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Limpa o valor do input de arquivo
      }
    }
  };

  const enviaArquivo = async () => {
    if (!arquivo) {
      return;
    }
    const dados = new FormData();
    dados.append("arquivo", arquivo);

    try {
      const resposta = await api.post(`/prerota/manual`, dados, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setArquivo(null);
      alert(resposta.data.message);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setArquivo(null);
      alert(error.response.data.error);
      console.log(error);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.titulo}>
        <h3>Enviar Mensagens Ativo</h3>
      </div>
      <div className={styles.conteudo}>
        <div className={styles.upload}>
          <label>
            <span>Upload do Arquivo</span>
            <MdCloudUpload />
            <input
              type="file"
              ref={fileInputRef}
              onChange={uploadArquivo}
              accept=".xlsx"
              className={styles.inputFile}
              hidden
            />
          </label>
          {arquivo ? <p>{formatarNomeArquivo(arquivo.name)}</p> : ""}
        </div>
        <Botao cor="primaria" onClick={enviaArquivo}>
          Enviar
        </Botao>
      </div>
    </div>
  );
};

export default EnviaAtivo;
