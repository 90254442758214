import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Usuario } from "../interfaces/IUsuario";
import api from "../utils/api";
import { useNavigate } from "react-router-dom";

interface UsuarioContextProps {
  usuario: Usuario | null;
  fazerLogin: (email: string, senha: string) => Promise<void>;
  fazerLogout: () => void;
  consultaUsuario: (id: string) => Promise<void>;
  usuarios: Usuario[];
  consultaUsuarios: () => Promise<void>;
}

const UsuarioContext = createContext<UsuarioContextProps | undefined>(
  undefined
);

export const UsuarioProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState<Usuario | null>(null);
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);

  useEffect(() => {
    if (!token && usuario) {
      fazerLogout();
    }
  }, [token, usuario]);

  const fazerLogin = async (email: string, senha: string) => {
    try {
      const resposta = await api.post(
        "/usuario/login",
        {
          email,
          senha,
        },
        {
          timeout: 60000,
        }
      );

      const dados = resposta.data;
      if (dados) {
        sessionStorage.setItem("token", dados.token);
        sessionStorage.setItem("usuarioId", dados.usuario);

        navigate("/chat");
        window.location.reload();
      } else {
        alert("Erro ao fazer login");
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  const fazerLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("userId");
    setUsuario(null);
    navigate("/");
    window.location.reload();
  };

  const consultaUsuario = async (idUsuario: string) => {
    try {
      const resposta = await api.get(`/usuario/${idUsuario}`);
      const dados = resposta.data;
      if (dados) {
        setUsuario(dados);
      } else {
        alert("usuario nao encontrado");
        fazerLogout();
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  const consultaUsuarios = async () => {
    try {
      const resposta = await api.get(`/usuario/`);
      const dados = resposta.data;
      if (dados) {
        setUsuarios(dados);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      alert(error.response.data.message);
    }
  };

  return (
    <UsuarioContext.Provider
      value={{
        usuario,
        fazerLogin,
        fazerLogout,
        consultaUsuario,
        consultaUsuarios,
        usuarios,
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
};

export function useUsuario() {
  const context = useContext(UsuarioContext);
  if (!context) {
    throw new Error(
      "useUsuario deve ser utilizado dentro de um UsuarioProvider"
    );
  }
  return context;
}
