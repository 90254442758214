import { useEffect, useState } from "react";
import styles from "./MonitoramentoEmAtendimento.module.css";
import { useDashboard } from "../../../../context/DashboardContext";
import { useProtocolo } from "../../../../context/ProtocoloContext";
import MonitoramentoModal from "../MonitoramentoModal";
import { Protocolo } from "../../../../interfaces/IProtocolo";

export default function MonitoramentoEmAtendimento() {
  const { populaEmAtendimento, emAtendimento } = useDashboard();
  const { buscaProtocolo } = useProtocolo();
  const [protocoloSelecionado, setProtocoloSelecionado] =
    useState<Protocolo | null>(null);
  const [modalAberto, setModalAberto] = useState<boolean>(false);

  useEffect(() => {
    populaEmAtendimento();
  }, []);

  const selecionandoProtocolo = async (numeroProtocolo: string) => {
    try {
      const protocolo = await buscaProtocolo(numeroProtocolo);
      setProtocoloSelecionado(protocolo);
      abreFechaModal();
    } catch (error) {
      console.log(error);
    }
  };

  const abreFechaModal = () => {
    setModalAberto(!modalAberto);
  };

  const emAtendimentoOrdenado = emAtendimento.sort((a, b) =>
    b.tma.localeCompare(a.tma)
  );

  return (
    <>
      {modalAberto && (
        <MonitoramentoModal
          abreModal={modalAberto}
          abreFechaModal={abreFechaModal}
          protocoloSelecionado={protocoloSelecionado}
        />
      )}
      <div className={styles.container}>
        <table>
          <thead>
            <tr>
              <th className={styles.atendimento}>Atendimento</th>
              <th className={styles.resposta}>Resposta</th>
              <th className={styles.espera}>Espera</th>
              <th className={styles.cliente}>Cliente</th>
              <th className={styles.atendente}>Atendente</th>
              <th className={styles.protocolo}>Protocolo</th>
            </tr>
          </thead>
          <tbody>
            {emAtendimento.length > 0 &&
              emAtendimentoOrdenado.map((item) => {
                return (
                  <tr
                    className={styles.tabela_conteudo}
                    key={item.protocolo}
                    onClick={() => selecionandoProtocolo(item.protocolo)}
                  >
                    <td className={styles.atendimento}>{item.tma}</td>
                    <td className={styles.resposta}>{item.tmr}</td>
                    <td className={styles.espera}>{item.tme}</td>
                    <td className={styles.cliente}>{item.cliente}</td>
                    <td className={styles.atendente}>{item.atendente}</td>
                    <td className={styles.protocolo}>{item.protocolo}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
}
