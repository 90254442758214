import moment from "moment";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import styles from "./AtendimentosItens.module.css";

interface AtendimentosItensProps {
  protocolo: Protocolo;
  atendente: string;
}
export default function AtendimentosItens({
  protocolo,
  atendente,
}: AtendimentosItensProps) {
  const formataNome = (nome: string) => {
    return nome.substring(0, 15);
  };

  return (
    <div className={styles.card}>
      <table className={styles.conteudo_protocolos}>
        <thead>
          <tr>
            <th>Protocolo</th>
            <th>Cliente</th>
            <th>Atendente</th>
            <th>Origem</th>
            <th>Status</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{protocolo.protocolo}</td>
            <td>
              {protocolo.de.nome.length > 18
                ? formataNome(protocolo.de.nome)
                : protocolo.de.nome}
            </td>
            <td>{atendente}</td>
            <td>{protocolo.origem}</td>
            <td>{protocolo.status}</td>
            <td>
              {protocolo.atualizadoEm
                ? moment(protocolo.atualizadoEm).format("DD/MM/yyyy")
                : moment(protocolo.criadoEm).format("DD/MM/yyyy")}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
