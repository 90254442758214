import Modal from "react-modal";
import styles from "./ModalHistoricoInfomacoes.module.css";
import { IoMdClose } from "react-icons/io";
import { Protocolo } from "../../../interfaces/IProtocolo";
import { useEffect, useState } from "react";
import { Minuta } from "../../../interfaces/IMinuta";
import api from "../../../utils/api";
import moment from "moment";

interface ModalHistoricoInfomacoesProps {
  abreModal: boolean;
  abreFechaModal: () => void;
  protocoloHistorico: Protocolo;
}

export default function ModalHistoricoInfomacoes({
  abreModal,
  abreFechaModal,
  protocoloHistorico,
}: ModalHistoricoInfomacoesProps) {
  const [minuta, setMinuta] = useState<Minuta>();
  const voltar = () => {
    abreFechaModal();
  };

  useEffect(() => {
    if (protocoloHistorico.minuta) {
      consultaMinuta();
    }
  }, [protocoloHistorico]);

  const consultaMinuta = async () => {
    try {
      const resposta = await api.get(
        `/minuta/numero/${protocoloHistorico.minuta}`
      );
      const dados: Minuta = resposta.data;

      if (dados) {
        setMinuta(dados);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={abreModal}
        ariaHideApp={false}
      >
        <div className={styles.card}>
          <div className={styles.cabecalho}>
            <div>
              <span>Informações</span>
            </div>
            <div className={styles.voltar} onClick={() => voltar()}>
              <IoMdClose />
            </div>
          </div>
          <div className={styles.corpo}>
            <table>
              <tr>
                <td className={styles.titulo}>Protocolo:</td>
                <td>{protocoloHistorico.protocolo}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Data:</td>
                <td>
                  {moment(protocoloHistorico.criadoEm).format("DD/MM/yyyy")}
                </td>
              </tr>
              <tr>
                <td className={styles.titulo}>Origem:</td>
                <td>{protocoloHistorico.origem}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Nome:</td>
                <td>{protocoloHistorico.de.nome}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Telefone:</td>
                <td>{protocoloHistorico.de.telefone}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Minuta:</td>
                <td>{protocoloHistorico.minuta}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Embarcador:</td>
                <td>{minuta?.embarcador?.nome}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Produto:</td>
                <td>{minuta?.descricaoProduto}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>ChaveNfe:</td>
                <td>{minuta?.chaveNfe}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Motivo:</td>
                <td>{minuta?.checklist?.motivo}</td>
              </tr>
              <tr>
                <td className={styles.titulo}>Detalhes:</td>
                <td>{minuta?.checklist?.detalhes}</td>
              </tr>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
}
