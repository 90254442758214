import { useEffect, useState } from "react";
import { Mensagem } from "../../../../interfaces/IMensagem";
import { Contato } from "../../../../interfaces/IContato";
import { LuCheckCheck, LuCheck } from "react-icons/lu";
import styles from "./MensagemImagem.module.css";
import api from "../../../../utils/api";
import Loading from "../../../Shared/Loading";
import ModalImagem from "../../ModalImagem";
import { Icon } from "@iconify-icon/react";
import { useOcorrencia } from "../../../../context/OcorrenciaContext";
import { useDepartamento } from "../../../../context/DepartamentoContext";

interface MensagemImagemProps {
  mensagem: Mensagem;
  contatoBot: Contato;
}

export default function MensagemImagem({
  mensagem,
  contatoBot,
}: MensagemImagemProps) {
  const [estaAberto, setEstaAberto] = useState<boolean>(false);
  const [imagemCarregada, setImagemCarregada] = useState(false);
  const { adicionarEvidencia } = useOcorrencia();
  const { departamento } = useDepartamento();

  const url = `https://chatbot.inectar.com.br/conversas/imagens/${mensagem.texto}`;
  const enviadoBot =
    mensagem.destinatario !== contatoBot.telefone &&
    mensagem.destinatario !== contatoBot.telefone2;
  const horaFormatada = new Date(mensagem.criadoEm).toLocaleTimeString(
    "pt-BR",
    { hour: "2-digit", minute: "2-digit" }
  );

  const abreFechaImagem = () => {
    setEstaAberto(!estaAberto);
  };

  const downloadMeta = async () => {
    try {
      if (
        mensagem.destinatario !== contatoBot.telefone &&
        mensagem.destinatario !== contatoBot.telefone2
      ) {
        setImagemCarregada(true);
        return;
      }
      const resposta = await api.get(`/mensagem/download/${mensagem._id}`);
      const dados = resposta.data;
      if (dados) {
        setImagemCarregada(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (url) {
      downloadMeta();
    }
  }, [url]);

  return (
    <>
      {estaAberto && (
        <ModalImagem
          isOpen={estaAberto}
          imagem={url}
          abreFechaImagem={abreFechaImagem}
        />
      )}
      <div
        className={`${styles.container} ${enviadoBot ? styles.remetente : ""}`}
      >
        <div
          className={`${styles.linha} ${enviadoBot ? styles.remetente : ""}`}
        >
          <div className={`${styles.conteudo}`}>
            <div className={styles.corpo}>
              {imagemCarregada ? (
                <>
                  <div className={styles.imagem}>
                    <img onClick={abreFechaImagem} src={url} alt="Imagem" />
                    {departamento.nome === "Motoristas" && (
                      <div
                        className={styles.icone}
                        onClick={() => adicionarEvidencia(url)}
                      >
                        <Icon icon="solar:paperclip-outline" />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className={styles.sem_imagem}>
                  <Loading />
                </div>
              )}
            </div>
            <div className={styles.rodape}>
              <span>{horaFormatada}</span>
              {enviadoBot && (
                <>
                  {mensagem.status === "delivered" ||
                  mensagem.status === "read" ? (
                    <LuCheckCheck />
                  ) : (
                    <LuCheck />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
