import React, { ReactNode, createContext, useContext, useState } from "react";
import api from "../utils/api";
import { useProtocolo } from "./ProtocoloContext";
import { Mensagem } from "../interfaces/IMensagem";
import { Contato } from "../interfaces/IContato";
import { useUsuario } from "./UsuarioContext";
import { useDepartamento } from "./DepartamentoContext";

interface MensagemContextProps {
  conversaAtual: Mensagem[] | undefined;
  contatoBot: Contato | undefined;
  buscaContatoBot: (departamento: string) => void;
  salvaConversaCache: (protocolo: string, mensagem: Mensagem[]) => void;
  adicionaMensagemCache: (mensagem: Mensagem) => void;
  apagaMensagensCache: (protocolo: string) => void;
  atualizaMensagem: (status: Mensagem) => void;
  atualizaStatus: (status: Mensagem) => void;
  buscaConversa: (protocolo: string) => void;
  enviaMensagemTexto: (texto: string) => void;
  enviaMensagemArquivo: (imagem: File, tipo: string) => void;
}

const MensagemContext = createContext<MensagemContextProps | undefined>(
  undefined
);

export const MensagemProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [contatoBot, setContatoBot] = useState<Contato>();
  const [conversaAtual, setConversaAtual] = useState<Mensagem[]>([]);
  const { protocoloSelecionado } = useProtocolo();
  const { usuario } = useUsuario();
  const { opcoesDepartamento } = useDepartamento();

  const buscaContatoBot = async (setor: string) => {
    try {
      const departamento = opcoesDepartamento.find(
        (item) => item.nome === setor
      );

      const reposta = await api.get(`/contato/adm/${departamento?.telefoneId}`);
      const dados = reposta.data;

      if (dados) {
        setContatoBot(dados);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buscaConversa = async (protocolo: string) => {
    try {
      const protocoloMensagens = buscaProtocoloCache(protocolo);

      if (protocoloMensagens) {
        setConversaAtual(protocoloMensagens);
      }

      const reposta = await api.get(`/mensagem/${protocolo}`);
      const dados: Mensagem[] = reposta.data;

      if (dados) {
        if (!protocoloMensagens || dados.length !== protocoloMensagens.length) {
          setConversaAtual(dados);
          salvaConversaCache(protocolo, dados);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const enviaMensagemTexto = async (texto: string) => {
    try {
      if (protocoloSelecionado && usuario) {
        const resposta = await api.post("/mensagem/texto", {
          texto: texto,
          telefone: protocoloSelecionado.de.telefone,
          protocolo: protocoloSelecionado.protocolo,
          usuarioId: usuario?._id,
          telefoneId: protocoloSelecionado.telefoneId,
        });
        const dados = resposta.data;
        setConversaAtual([...conversaAtual, dados]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const enviaMensagemArquivo = async (arquivo: File, tipo: string) => {
    try {
      if (protocoloSelecionado && usuario) {
        const dataForm = new FormData();
        dataForm.append("arquivo", arquivo);
        dataForm.append("texto", "Arquivo");
        dataForm.append("telefone", protocoloSelecionado.de.telefone);
        dataForm.append("protocolo", protocoloSelecionado.protocolo);
        dataForm.append("usuarioId", usuario._id);
        dataForm.append("telefoneId", protocoloSelecionado.telefoneId);
        dataForm.append("tipo", tipo);

        const resposta = await api.post("/mensagem/arquivo", dataForm);
        const dados = resposta.data;

        setConversaAtual([...conversaAtual, dados]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const atualizaMensagem = (novaMensagem: Mensagem) => {
    setConversaAtual((msg) => [...msg, novaMensagem]);
  };

  const atualizaStatus = (status: Mensagem) => {
    setConversaAtual((mensagensAntigas) =>
      mensagensAntigas.map((msg) =>
        msg._id === status._id ? { ...msg, status: status.status } : msg
      )
    );
  };

  const buscaProtocoloCache = (protocolo: string): Mensagem[] | null => {
    const chave = `protocolo-${protocolo}`;

    const mensagensJson = sessionStorage.getItem(chave);

    if (mensagensJson) {
      const mensagens: Mensagem[] = JSON.parse(mensagensJson);
      return mensagens;
    }

    return null;
  };

  const salvaConversaCache = (
    protocolo: string,
    mensagens: Mensagem[]
  ): void => {
    const chave = `protocolo-${protocolo}`;

    const mensagensJson = JSON.stringify(mensagens);

    sessionStorage.setItem(chave, mensagensJson);
  };

  const adicionaMensagemCache = (novaMensagem: Mensagem) => {
    const chave = `protocolo-${novaMensagem.protocolo}`;

    const mensagensJson = sessionStorage.getItem(chave);

    if (mensagensJson) {
      const mensagens: Mensagem[] = JSON.parse(mensagensJson);

      mensagens.push(novaMensagem);

      sessionStorage.setItem(chave, JSON.stringify(mensagens));
    }
  };

  const apagaMensagensCache = (protocolo: string) => {
    const chave = `protocolo-${protocolo}`;

    sessionStorage.removeItem(chave);
  };

  return (
    <MensagemContext.Provider
      value={{
        conversaAtual,
        contatoBot,
        enviaMensagemTexto,
        buscaContatoBot,
        buscaConversa,
        adicionaMensagemCache,
        apagaMensagensCache,
        salvaConversaCache,
        atualizaMensagem,
        atualizaStatus,
        enviaMensagemArquivo,
      }}
    >
      {children}
    </MensagemContext.Provider>
  );
};

export function useMensagem() {
  const context = useContext(MensagemContext);
  if (!context) {
    throw new Error(
      "useMensagem deve ser utilizado dentro de um MensagemProvider"
    );
  }
  return context;
}
