import moment, { Moment } from "moment";
import Botao from "../../../../Shared/Botao";
import DatePickerPeriodo from "../../../../Shared/DatePickerPeriodo";
import SelectGrupo from "../../../../Shared/SelectGrupo";
import styles from "./MonitoramentoGraficosTempoMedio.module.css";
import { useEffect, useState } from "react";
import { useUsuario } from "../../../../../context/UsuarioContext";
interface filtro {
  dataInicial: Moment | null;
  dataFinal: Moment | null;
  usuario: string[];
}

export default function MonitoramentoGraficoTempoMedio() {
  const [filtro, setFiltro] = useState<filtro>({
    dataInicial: moment(),
    dataFinal: moment(),
    usuario: ["Todos"],
  });

  const { usuarios, consultaUsuarios } = useUsuario();

  const nomesDosUsuarios = usuarios
    .map((usuario) => usuario.nome)
    .sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    if (usuarios.length === 0) {
      consultaUsuarios();
    }
  }, [usuarios, filtro]);

  const buscarDados = async () => {
    ("");
  };

  return (
    <>
      <div className={styles.filtro}>
        <div className={styles.periodo}>
          <DatePickerPeriodo
            value={[filtro.dataInicial, filtro.dataFinal]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({
                ...prev,
                dataInicial: valor ? valor[0] : moment(),
                dataFinal: valor ? valor[1] : moment(),
              }))
            }
          />
        </div>
        <div className={styles.inputGrupo}>
          <SelectGrupo
            placeholder="Usuarios"
            value={filtro.usuario}
            opcoes={["Todos", ...nomesDosUsuarios]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({ ...prev, usuario: valor }))
            }
          />
        </div>

        <div>
          <Botao cor={"primaria"} onClick={buscarDados}>
            Buscar
          </Botao>
        </div>
      </div>
      <div className={styles.corpo}></div>
    </>
  );
}
