import { useEffect, useState } from "react";
import styles from "./Informativo.module.css";
import InformativoHeader from "./InformativoHeader";
import InformativoBody from "./InformativoBody";
import { Protocolo } from "../../../interfaces/IProtocolo";
import InformativoHistorico from "./InformativoHistorico";
import { useDepartamento } from "../../../context/DepartamentoContext";
import InformativoBodyMotorista from "./InformativoBodyMotorista";

interface InformativoProps {
  protocoloSelecionado: Protocolo;
}

export default function Informativo({
  protocoloSelecionado,
}: InformativoProps) {
  const [abaAberta, setAbaAberta] = useState("Informacoes");
  const { departamento } = useDepartamento();

  useEffect(() => {
    setAbaAberta("Informacoes");
  }, [protocoloSelecionado]);

  return (
    <div className={styles.container}>
      <InformativoHeader setAbaAberta={setAbaAberta} abaAberta={abaAberta} />
      {abaAberta === "Informacoes" && departamento.nome === "SAC" && (
        <InformativoBody protocoloSelecionado={protocoloSelecionado} />
      )}
      {abaAberta === "Informacoes" && departamento.nome === "Motoristas" && (
        <InformativoBodyMotorista protocoloSelecionado={protocoloSelecionado} />
      )}
      {abaAberta === "Historico" && (
        <InformativoHistorico protocoloSelecionado={protocoloSelecionado} />
      )}
    </div>
  );
}
