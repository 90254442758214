import Modal from "react-modal";
import styles from "./ModalImagem.module.css";
import { IoCloseOutline } from "react-icons/io5";

interface ModalImagemProps {
  isOpen: boolean;
  imagem: string;
  abreFechaImagem: () => void;
}

export default function ModalImagem({
  isOpen,
  imagem,
  abreFechaImagem,
}: ModalImagemProps) {
  return (
    <div>
      <div className={styles.fechar} onClick={abreFechaImagem}>
        <IoCloseOutline />
      </div>
      <Modal
        className={styles.modal}
        isOpen={isOpen}
        ariaHideApp={false}
        overlayClassName={styles.overlay}
        onRequestClose={abreFechaImagem}
      >
        <div className={styles.modalConteudo}>
          <img src={imagem} alt="Imagem" />
        </div>
      </Modal>
    </div>
  );
}
