import moment, { Moment } from "moment";
import { useEffect, useState } from "react";
import { Protocolo } from "../../../../../interfaces/IProtocolo";
import { useUsuario } from "../../../../../context/UsuarioContext";
import api from "../../../../../utils/api";
import GraficoBarras from "../GraficoBarras";
import SelectGrupo from "../../../../Shared/SelectGrupo";
import Botao from "../../../../Shared/Botao";
import SelectInput from "../../../../Shared/SelectInput";
import DatePickerPeriodo from "../../../../Shared/DatePickerPeriodo";
import styles from "./MonitoramentoGraficosProtocolos.module.css";

interface QuantidadePorDia {
  data: string;
  quantidade: number;
}

interface filtro {
  dataInicial: Moment | null;
  dataFinal: Moment | null;
  usuario: string[];
  setor: string;
  status: string;
  tipo: string;
}

export default function MonitoramentoGraficoProtocolo() {
  const [filtro, setFiltro] = useState<filtro>({
    dataInicial: moment(),
    dataFinal: moment(),
    usuario: ["Todos"],
    setor: "Todos",
    status: "Todos",
    tipo: "Todos",
  });
  const [quantidadePorDia, setQuantidadePorDia] = useState<QuantidadePorDia[]>(
    []
  );
  const [protocolos, setProtocolos] = useState<Protocolo[]>([]);
  const { usuarios, consultaUsuarios } = useUsuario();

  useEffect(() => {
    if (usuarios.length === 0) {
      consultaUsuarios();
    }

    if (protocolos.length > 0) {
      trataDados();
    }
  }, [usuarios, protocolos, filtro]);

  const buscarDados = async () => {
    try {
      const resposta = await api.get(
        `/protocolo/periodo/${filtro.dataInicial?.format(
          "DD-MM-yyyy"
        )}/${filtro.dataFinal?.format("DD-MM-yyyy")}`
      );

      const dados = resposta.data;
      setProtocolos(dados);
    } catch (error) {
      console.log(error);
    }
  };

  const trataDados = () => {
    setQuantidadePorDia([]);

    // Função auxiliar para verificar se um protocolo atende aos critérios
    const protocoloValido = (protocolo: Protocolo) => {
      const setorValido =
        filtro.setor === "Todos" ||
        (filtro.setor === "Consumidor" &&
          protocolo.telefoneId ===
            process.env.REACT_APP_TELEFONEID_CONSUMIDOR) ||
        (filtro.setor === "Motoristas" &&
          protocolo.telefoneId === process.env.REACT_APP_TELEFONEID_MOTORISTA);

      const tipoValido =
        filtro.tipo === "Todos" ||
        (filtro.tipo === "Ativo" && protocolo.origem === "ativo") ||
        (filtro.tipo === "Receptivo" && protocolo.origem === "receptivo");

      const statusValido =
        filtro.status === "Todos" ||
        protocolo.status === filtro.status.toLocaleLowerCase();

      const usuarioValido =
        filtro.usuario.includes("Todos") ||
        (filtro.usuario.includes("Bot") && !protocolo.atendente) ||
        usuarios.some(
          (usuario) =>
            filtro.usuario.includes(usuario.nome) &&
            protocolo.atendente === usuario._id
        );

      return setorValido && tipoValido && statusValido && usuarioValido;
    };

    // Processar todos os protocolos
    protocolos.forEach((protocolo) => {
      if (protocoloValido(protocolo)) {
        incrementarQuantidadePorDia(
          moment(protocolo.atualizadoEm).format("DD/MM/yyyy")
        );
      }
    });
  };

  const incrementarQuantidadePorDia = (data: string) => {
    setQuantidadePorDia((prevQuantidadePorDia) => {
      // Usar um mapa para atualizar a quantidade mais eficientemente
      const quantidadeMap = new Map(
        prevQuantidadePorDia.map((entry) => [entry.data, entry.quantidade])
      );

      // Atualizar o valor ou adicionar uma nova entrada
      quantidadeMap.set(data, (quantidadeMap.get(data) || 0) + 1);

      // Transformar o mapa de volta para um array
      return Array.from(quantidadeMap, ([data, quantidade]) => ({
        data,
        quantidade,
      }));
    });
  };

  const calcularTotal = () => {
    return quantidadePorDia.reduce(
      (total, entry) => total + entry.quantidade,
      0
    );
  };

  const nomesDosUsuarios = usuarios
    .map((usuario) => usuario.nome)
    .sort((a, b) => a.localeCompare(b));

  return (
    <>
      <div className={styles.filtro}>
        <div className={styles.periodo}>
          <DatePickerPeriodo
            value={[filtro.dataInicial, filtro.dataFinal]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({
                ...prev,
                dataInicial: valor ? valor[0] : moment(),
                dataFinal: valor ? valor[1] : moment(),
              }))
            }
          />
        </div>
        <div className={styles.inputs}>
          <SelectInput
            placeholder="setor"
            value={filtro.setor}
            opcoes={["Todos", "Consumidor", "Motoristas"]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({ ...prev, setor: valor }))
            }
          />
        </div>

        <div className={styles.inputs}>
          <SelectInput
            placeholder="Tipo"
            value={filtro.tipo}
            opcoes={["Todos", "Ativo", "Receptivo"]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({ ...prev, tipo: valor }))
            }
          />
        </div>

        <div className={styles.inputs}>
          <SelectInput
            placeholder="Status"
            value={filtro.status}
            opcoes={["Todos", "Ura", "Espera", "Atendimento", "Finalizado"]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({ ...prev, status: valor }))
            }
          />
        </div>

        <div className={styles.inputGrupo}>
          <SelectGrupo
            placeholder="Usuarios"
            value={filtro.usuario}
            opcoes={["Todos", "Bot", ...nomesDosUsuarios]}
            aoAlterado={(valor) =>
              setFiltro((prev) => ({ ...prev, usuario: valor }))
            }
          />
        </div>

        <div>
          <Botao cor={"primaria"} onClick={buscarDados}>
            Buscar
          </Botao>
        </div>
      </div>
      <div className={styles.corpo}>
        <GraficoBarras quantidadePorDia={quantidadePorDia} />
        <div className={styles.card}>
          <label>Total</label>
          <label>{calcularTotal()}</label>
        </div>
      </div>
    </>
  );
}
