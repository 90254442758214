import { useProtocolo } from "../../../../context/ProtocoloContext";
import styles from "./SidebarContatos.module.css";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import { useMensagem } from "../../../../context/MensagensContext";
import { useEffect, useState } from "react";
import { Icon } from "@iconify-icon/react";

interface SidebarContatosProps {
  protocolo: Protocolo;
}

export default function SidebarContatos({ protocolo }: SidebarContatosProps) {
  const {
    protocoloSelecionado,
    setProtocoloSelecionado,
    notificacao,
    removeNotificacao,
  } = useProtocolo();
  const [novaNotificacao, setNovaNotificacao] = useState<number>(0);
  const { buscaConversa } = useMensagem();
  const isActive = protocoloSelecionado?._id === protocolo._id;

  useEffect(() => {
    const validaNotificacao = () => {
      const indexNotificacao = notificacao.findIndex(
        (item) => item.protocoloNumero === protocolo.protocolo
      );
      if (indexNotificacao !== -1) {
        const notificacoesAtualizadas = [...notificacao];
        setNovaNotificacao(
          notificacoesAtualizadas[indexNotificacao].quantidade
        );
      } else {
        setNovaNotificacao(0);
      }
    };

    validaNotificacao();
  }, [notificacao, protocoloSelecionado]);

  const nomeEditado = (name: string) => {
    let editedName = name
      .split(" ")
      .map(
        (palavra) =>
          palavra.charAt(0).toUpperCase() + palavra.slice(1).toLowerCase()
      )
      .join(" ");

    if (editedName.length > 30) {
      editedName = editedName.slice(0, 25) + "...";
    }

    return editedName;
  };

  const selecionaContato = (protocolo: Protocolo) => {
    setProtocoloSelecionado(protocolo);
    buscaConversa(protocolo.protocolo);
    removeNotificacao(protocolo.protocolo);
  };

  return (
    <div
      className={`${styles.container} ${isActive ? styles.selecionada : ""}`}
      onClick={() => {
        selecionaContato(protocolo);
      }}
    >
      <div className={styles.conteudo}>
        <div>
          <Icon className={styles.avatar} icon="carbon:user-avatar-filled" />
        </div>
        <div>
          <span className={styles.nome}>{nomeEditado(protocolo.de.nome)}</span>
        </div>
      </div>
      <div className={styles.notificacao}>
        {novaNotificacao !== 0 && (
          <div className={styles.alerta}>{novaNotificacao}</div>
        )}
      </div>
    </div>
  );
}
