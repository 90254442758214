import React, { ReactNode, createContext, useContext, useState } from "react";

interface DepartamentoProviderProps {
  children: ReactNode;
}
interface DepartamentoContextProps {
  opcoesDepartamento: { nome: string; telefoneId: string }[];
  departamento: { nome: string; telefoneId: string };
  setDepartamento: React.Dispatch<
    React.SetStateAction<{ nome: string; telefoneId: string }>
  >;
}

const DepartamentoContext = createContext<DepartamentoContextProps | undefined>(
  undefined
);

export function DepartamentoProvider({ children }: DepartamentoProviderProps) {
  const sac = process.env.REACT_APP_TELEFONEID_CONSUMIDOR || " ";
  const motorista = process.env.REACT_APP_TELEFONEID_MOTORISTA || " ";
  const [departamento, setDepartamento] = useState({
    nome: "SAC",
    telefoneId: sac,
  });
  const opcoesDepartamento = [
    { nome: "SAC", telefoneId: sac },
    { nome: "Motoristas", telefoneId: motorista },
  ];

  return (
    <DepartamentoContext.Provider
      value={{
        opcoesDepartamento,
        departamento,
        setDepartamento,
      }}
    >
      {children}
    </DepartamentoContext.Provider>
  );
}

export function useDepartamento() {
  const context = useContext(DepartamentoContext);
  if (!context) {
    throw new Error(
      "useDepartamento deve ser utilizado dentro de um DepartamentoProvider"
    );
  }
  return context;
}
