import HistoricoConversa from "./HistoricoConversa";
import styles from "./Relatorios.module.css";
import RelatorioDisparoMensagens from "./RelatorioDisparoMensagens";
import RelatorioTempoAtendimento from "./RelatorioTempoAtendimento";

export default function Relatorios() {
  return (
    <div className={styles.container}>
      <h2>Relatorios</h2>
      <div className={styles.conteudo}>
        <HistoricoConversa />
        <RelatorioDisparoMensagens />
        <RelatorioTempoAtendimento />
      </div>
    </div>
  );
}
