import React, { ReactNode, createContext, useContext, useState } from "react";
import api from "../utils/api";
import { Protocolo } from "../interfaces/IProtocolo";
import { useUsuario } from "./UsuarioContext";
import { useDepartamento } from "./DepartamentoContext";

interface ProtocoloContextProps {
  protocoloSelecionado: Protocolo | undefined;
  protocolosEmAtendimento: Protocolo[];
  setProtocoloSelecionado: (protocolo: Protocolo | undefined) => void;
  aguardando: number;
  setAguardando: React.Dispatch<React.SetStateAction<number>>;
  aguardandoMotorista: number;

  setAguardandoMotorista: React.Dispatch<React.SetStateAction<number>>;
  atualizaEsperaAtendimento: () => void;
  emEsperaParaAtendimento: () => void;
  notificacao: INotificacao[];
  adicionaNotificacao: (protocolo: string) => void;
  removeNotificacao: (protocolo: string) => void;
  buscaProtocolo: (numeroProtocolo: string) => Promise<Protocolo | null>;
}

const ProtocoloContext = createContext<ProtocoloContextProps | undefined>(
  undefined
);

interface INotificacao {
  protocoloNumero: string;
  quantidade: number;
}

export const ProtocoloProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { usuario } = useUsuario();
  const { departamento } = useDepartamento();
  const [aguardando, setAguardando] = useState<number>(0);
  const [aguardandoMotorista, setAguardandoMotorista] = useState<number>(0);
  const [notificacao, setNotificacao] = useState<INotificacao[]>([]);
  const [protocolosEmAtendimento, setProtocolosEmAtendimento] = useState<
    Protocolo[]
  >([]);
  const [protocoloSelecionado, setProtocoloSelecionado] = useState<
    Protocolo | undefined
  >(undefined);

  const atualizaEsperaAtendimento = async () => {
    try {
      emAtendimento();
      emEspera();
    } catch (error) {
      console.log(error);
    }
  };

  const emEspera = async () => {
    try {
      const resposta = await api.get(`protocolo/espera/`);
      const dados = resposta.data;
      if (dados) {
        setAguardando(dados.quantidade);
        setAguardandoMotorista(dados.quantidadeMotorista);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emAtendimento = async () => {
    try {
      if (usuario) {
        const resposta = await api.get(`protocolo/${usuario._id}`);
        const dados = resposta.data;
        setProtocolosEmAtendimento(dados);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emEsperaParaAtendimento = async () => {
    try {
      if (usuario) {
        const resposta = await api.put(`protocolo/espera/${usuario._id}`, {
          departamento: departamento.nome,
        });
        const dados = resposta.data;
        if (dados)
          setProtocolosEmAtendimento([...protocolosEmAtendimento, dados]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const adicionaNotificacao = async (protocolo: string) => {
    try {
      setNotificacao((notificacoesAtuais) => {
        // Verifica se a notificação já existe
        const notificacaoExistente = notificacoesAtuais.find(
          (item) => item.protocoloNumero === protocolo
        );

        if (notificacaoExistente) {
          // Se a notificação já existe, atualiza a quantidade
          return notificacoesAtuais.map((item) =>
            item.protocoloNumero === protocolo
              ? { ...item, quantidade: item.quantidade + 1 }
              : item
          );
        } else {
          // Se a notificação não existe, adiciona à lista de notificações
          return [
            ...notificacoesAtuais,
            { protocoloNumero: protocolo, quantidade: 1 },
          ];
        }
      });
      const audio = new Audio("/notification-tone-swift-gesture.mp3");
      audio.play();
    } catch (error) {
      console.error("Erro ao adicionar notificação:", error);
    }
  };

  const removeNotificacao = (protocolo: string) => {
    const indexToRemove = notificacao.findIndex(
      (item) => item.protocoloNumero === protocolo
    );

    if (indexToRemove !== -1) {
      const notificacoesAtualizadas = [...notificacao];
      notificacoesAtualizadas.splice(indexToRemove, 1);
      setNotificacao(notificacoesAtualizadas);
    }
  };

  const buscaProtocolo = async (
    numeroProtocolo: string
  ): Promise<Protocolo | null> => {
    try {
      const resposta = await api.get<Protocolo>(
        `/protocolo/protocolo/${numeroProtocolo}`
      );
      const protocolo: Protocolo = resposta.data;
      return protocolo;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <ProtocoloContext.Provider
      value={{
        protocoloSelecionado,
        setProtocoloSelecionado,
        emEsperaParaAtendimento,
        protocolosEmAtendimento,
        aguardando,
        setAguardando,
        atualizaEsperaAtendimento,
        notificacao,
        adicionaNotificacao,
        removeNotificacao,
        setAguardandoMotorista,
        aguardandoMotorista,
        buscaProtocolo,
      }}
    >
      {children}
    </ProtocoloContext.Provider>
  );
};

export function useProtocolo() {
  const context = useContext(ProtocoloContext);
  if (!context) {
    throw new Error(
      "useProtocolo deve ser utilizado dentro de um ProtocoloProvider"
    );
  }
  return context;
}
