import React, { FormEvent, Dispatch, SetStateAction, useState } from "react";
import styles from "./Login.module.css";
import CampoInput from "../../Shared/CampoInput";
import Botao from "../../Shared/Botao";
import { useUsuario } from "../../../context/UsuarioContext";
import RecuperarSenha from "../RecuperarSenha";

interface Props {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export default function FomularioLogin({ setLoading }: Props) {
  const [abrirModalSenha, setAbrirModalSenha] = useState<boolean>();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { fazerLogin } = useUsuario();

  const aoPressionarTecla = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      validaUsuarioSenha(e);
    }
  };

  const validaUsuarioSenha = async (e: FormEvent) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true);
      await fazerLogin(email, password);
      setLoading(false);
    } else {
      alert("Preencha os campos Email e Senha");
    }
  };

  const abreFechaModal = () => {
    setAbrirModalSenha(!abrirModalSenha);
  };

  return (
    <>
      {abrirModalSenha && (
        <RecuperarSenha
          isOpen={abrirModalSenha}
          abrirFecharModal={abreFechaModal}
        />
      )}
      <div className={styles.formulario}>
        <form onSubmit={validaUsuarioSenha}>
          <div className={styles.cartao__header}>
            <img src="/Logo Trimbot.png" />
            <h2>trimBOT</h2>
          </div>
          <div className={styles.cartao__body}>
            <div>
              <CampoInput
                required={true}
                type="email"
                placeholder="Email"
                value={email}
                aoAlterado={(value) => setEmail(value)}
                onKeyDown={aoPressionarTecla}
              />
            </div>
            <div>
              <CampoInput
                required={true}
                type="password"
                placeholder="Senha"
                value={password}
                aoAlterado={(value) => setPassword(value)}
                onKeyDown={aoPressionarTecla}
              />
            </div>
          </div>
          <div className={styles.cartao__footer}>
            <Botao cor={"primaria"} onClick={validaUsuarioSenha}>
              Entrar
            </Botao>
            <p onClick={abreFechaModal}>Esqueci a senha</p>
          </div>
        </form>
      </div>
    </>
  );
}
