import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useProtocolo } from "./ProtocoloContext";
import api from "../utils/api";

interface OcorrenciaContextProps {
  evidencias: string[];
  adicionarEvidencia: (url: string) => void;
  removerEvidencia: (index: number) => void;
  enviarEvidencia: (minuta: string) => void;
}

const OcorrenciaContext = createContext<OcorrenciaContextProps | undefined>(
  undefined
);

export const OcorrenciaProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { protocoloSelecionado } = useProtocolo();
  const [evidencias, setEvidencias] = useState<string[]>([]);

  useEffect(() => {
    setEvidencias([]);
  }, [protocoloSelecionado]);

  const adicionarEvidencia = (url: string) => {
    setEvidencias((prev) => [...prev, url]);
  };

  const removerEvidencia = (index: number) => {
    setEvidencias((prev) => prev.filter((_, i) => i !== index));
  };

  const enviarEvidencia = async (minuta: string) => {
    try {
      const resposta = await api.post("/esl/enviar_imagens", {
        minuta,
        evidencias,
      });

      if (resposta.data) {
        alert(resposta.data.message);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log(error);
      alert(error.response.data.message);
    }
  };

  return (
    <OcorrenciaContext.Provider
      value={{
        evidencias,
        adicionarEvidencia,
        removerEvidencia,
        enviarEvidencia,
      }}
    >
      {children}
    </OcorrenciaContext.Provider>
  );
};

export function useOcorrencia() {
  const context = useContext(OcorrenciaContext);
  if (!context) {
    throw new Error(
      "useOcorrencia deve ser utilizado dentro de um UsuarioProvider"
    );
  }
  return context;
}
