import { useRef, useState, ChangeEvent } from "react";
import styles from "./EnvioArquivos.module.css";
import { HiOutlineDocumentText } from "react-icons/hi";
import { FaRegFileImage } from "react-icons/fa";
import Botao from "../../Shared/Botao";

import { useMensagem } from "../../../context/MensagensContext";

interface EnviaArquivoProps {
  abreAbaDocument: () => void;
}

export default function EnvioArquivos({ abreAbaDocument }: EnviaArquivoProps) {
  const fileInputDocumentoRef = useRef<HTMLInputElement>(null);
  const fileInputImagemRef = useRef<HTMLInputElement>(null);
  const [arquivo, setArquivo] = useState<File | null>(null);
  const [tipoMensagem, setTipoMensagem] = useState<string>("");
  const [nomeArquivo, setNomeArquivo] = useState<string>("");
  const { enviaMensagemArquivo } = useMensagem();

  const formatarNomeArquivo = (nome: string): string => {
    const nomeArquivoFormatado =
      nome.length > 20 ? nome.substring(0, 20) + "..." : nome;

    return nomeArquivoFormatado;
  };

  const selecionaArquivoDocumento = () => {
    setArquivo(null);
    if (fileInputDocumentoRef.current) {
      fileInputDocumentoRef.current.accept = "application/pdf";
      fileInputDocumentoRef.current.click();
    }
    setTipoMensagem("documento");
  };

  const selecionaArquivoImagem = () => {
    setArquivo(null);
    if (fileInputImagemRef.current) {
      fileInputImagemRef.current.accept = ".jpeg, .jpg, .png";
      fileInputImagemRef.current.click();
    }
    setTipoMensagem("imagem");
  };

  const uploadArquivo = (e: ChangeEvent<HTMLInputElement>) => {
    const novoArquivo = e.target.files && e.target.files[0];
    if (novoArquivo) {
      setArquivo(novoArquivo);

      const nomeArquivoFormatado = formatarNomeArquivo(novoArquivo.name);
      setNomeArquivo(nomeArquivoFormatado);

      if (fileInputDocumentoRef.current) {
        fileInputDocumentoRef.current.value = "";
      }
      if (fileInputImagemRef.current) {
        fileInputImagemRef.current.value = "";
      }
    }
  };

  const enviaArquivo = async () => {
    if (tipoMensagem === "imagem" && arquivo) {
      enviaMensagemArquivo(arquivo, "image");
    } else if (tipoMensagem === "documento" && arquivo) {
      enviaMensagemArquivo(arquivo, "document");
    }

    abreAbaDocument();
    setArquivo(null);
  };

  return (
    <div className={styles.container}>
      <div className={styles.cartao}>
        <div className={styles.tipos}>
          <div className={styles.item} onClick={selecionaArquivoDocumento}>
            <HiOutlineDocumentText />
            <span>Enviar PDF</span>
            <input
              type="file"
              ref={fileInputDocumentoRef}
              onChange={uploadArquivo}
              accept="application/pdf"
              hidden
            />
          </div>
          <div className={styles.item} onClick={selecionaArquivoImagem}>
            <FaRegFileImage />
            <span>Enviar Imagem</span>
            <input
              type="file"
              ref={fileInputImagemRef}
              onChange={uploadArquivo}
              accept=".jpeg, .jpg, .png"
              hidden
            />
          </div>
        </div>
        {arquivo && (
          <div className={styles.arquivo}>
            <span>{nomeArquivo}</span>
            <Botao cor="primaria" onClick={enviaArquivo}>
              Enviar
            </Botao>
          </div>
        )}
      </div>
    </div>
  );
}
