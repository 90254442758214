import React, { useEffect, useRef, useState } from "react";
import styles from "./ConversasFooter.module.css";
import { useMensagem } from "../../../../context/MensagensContext";
import { useSocket } from "../../../../context/SocketContext";
import Emoji from "../../Emoji";
import MensagemPronta from "../../Mensagens/MensagemPronta";
import EnvioArquivos from "../../EnvioArquivos";
import { Protocolo } from "../../../../interfaces/IProtocolo";
import { Icon } from "@iconify-icon/react";

interface ConversasFooterProps {
  protocoloSelecionado: Protocolo;
}

export default function ConversasFooter({
  protocoloSelecionado,
}: ConversasFooterProps) {
  const [isActiveEmoji, setIsActiveEmoji] = useState(false);
  const [isActiveTemplate, setIsActiveTemplate] = useState(false);
  const [isActiveDocument, setIsActiveDocument] = useState(false);
  const [mensagem, setMensagem] = useState("");
  const { enviaMensagemTexto } = useMensagem();
  const { online } = useSocket();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (protocoloSelecionado) {
      setMensagem("");
    }
  }, [protocoloSelecionado]);

  const abreAbaEmoji = () => {
    if (isActiveTemplate) abreAbaTemplate();
    if (isActiveDocument) abreAbaDocument();
    setIsActiveEmoji(!isActiveEmoji);
  };

  const abreAbaTemplate = () => {
    if (isActiveEmoji) abreAbaEmoji();
    if (isActiveDocument) abreAbaDocument();
    setIsActiveTemplate(!isActiveTemplate);
  };

  const abreAbaDocument = () => {
    if (isActiveEmoji) abreAbaEmoji();
    if (isActiveTemplate) abreAbaTemplate();
    setIsActiveDocument(!isActiveDocument);
  };

  const aoPressionarTecla = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setMensagem((prevMensagem) => prevMensagem + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault();
      enviarMensagem();
    }
  };

  const darFocoAoTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const enviarMensagem = () => {
    if (online) {
      enviaMensagemTexto(mensagem);
      setMensagem("");
      darFocoAoTextarea();

      if (isActiveDocument) abreAbaDocument();
      if (isActiveEmoji) abreAbaEmoji();
      if (isActiveTemplate) abreAbaTemplate();
    } else {
      alert("Fique online para enviar mensagens");
    }
  };

  return (
    <>
      {isActiveTemplate && (
        <MensagemPronta
          setMensagem={setMensagem}
          abreAbaTemplate={abreAbaTemplate}
          darFocoAoTextarea={darFocoAoTextarea}
        />
      )}
      {isActiveEmoji && <Emoji setMensagem={setMensagem} />}
      {isActiveDocument && <EnvioArquivos abreAbaDocument={abreAbaDocument} />}
      <div className={styles.container}>
        <div className={styles.texto}>
          <textarea
            ref={textareaRef}
            placeholder="Mensagem"
            onChange={(e) => setMensagem(e.target.value)}
            onKeyDown={aoPressionarTecla}
            value={mensagem}
          />
          <Icon
            className={styles.enviar}
            icon="material-symbols:send"
            onClick={() => enviarMensagem()}
          />
        </div>
        <div className={styles.rodape}>
          <div className={styles.funcoes}>
            <Icon onClick={abreAbaTemplate} icon="ph:file-duotone" />
            <Icon icon="bi:chat-square-text" onClick={abreAbaDocument} />
            <Icon icon="fluent:emoji-laugh-20-regular" onClick={abreAbaEmoji} />
          </div>
        </div>
      </div>
    </>
  );
}
