import styles from "./CampoInput.module.css";

interface Props {
  required: boolean;
  type: string;
  placeholder?: string;
  children?: string;
  value: string;
  aoAlterado: (value: string) => void;
  semMargem?: boolean;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function CampoInput({
  required = false,
  type,
  placeholder,
  children,
  value,
  aoAlterado,
  semMargem,
  onKeyDown,
}: Props) {
  return (
    <>
      {children && <label className={styles.label}>{children}</label>}
      <input
        required={required}
        className={`${styles.container} ${semMargem ? styles.semMargem : ""}`}
        type={type}
        placeholder={placeholder}
        onChange={(evento) => aoAlterado(evento.target.value)}
        value={value}
        onKeyDown={onKeyDown}
      ></input>
    </>
  );
}
