import { useEffect, useState } from "react";
import { Mensagem } from "../../../../interfaces/IMensagem";
import { Contato } from "../../../../interfaces/IContato";
import { LuCheckCheck, LuCheck } from "react-icons/lu";
import { IoDocumentOutline } from "react-icons/io5";
import { IoMdDownload } from "react-icons/io";
import styles from "./MensagemDocumento.module.css";
import api from "../../../../utils/api";
import Loading from "../../../Shared/Loading";

interface MensagemDocumentoProps {
  mensagem: Mensagem;
  contatoBot: Contato;
}

export default function MensagemDocumento({
  mensagem,
  contatoBot,
}: MensagemDocumentoProps) {
  const [documentoCarregado, setDocumentoCarregado] = useState(false);
  const linkDocumento = `https://chatbot.inectar.com.br/conversas/arquivos/${mensagem.texto}`;
  const enviadoBot =
    mensagem.destinatario !== contatoBot.telefone &&
    mensagem.destinatario !== contatoBot.telefone2;
  const horaFormatada = new Date(mensagem.criadoEm).toLocaleTimeString(
    "pt-BR",
    { hour: "2-digit", minute: "2-digit" }
  );

  const formatarNomeArquivo = (nome: string) => {
    const nomeArquivoFormatado =
      nome.length > 20 ? nome.substring(0, 20) + "..." : nome;

    return nomeArquivoFormatado;
  };

  const baixarArquivo = async () => {
    window.open(linkDocumento, "_blank");
  };

  const downloadMeta = async () => {
    try {
      if (
        mensagem.destinatario !== contatoBot.telefone &&
        mensagem.destinatario !== contatoBot.telefone2
      ) {
        setDocumentoCarregado(true);
        return;
      }
      const resposta = await api.get(`/mensagem/download/${mensagem._id}`);
      const dados = resposta.data;
      if (dados) {
        setDocumentoCarregado(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (linkDocumento) {
      downloadMeta();
    }
  }, [linkDocumento]);

  return (
    <div
      className={`${styles.container} ${enviadoBot ? styles.remetente : ""}`}
    >
      <div className={`${styles.linha} ${enviadoBot ? styles.remetente : ""}`}>
        <div className={`${styles.conteudo}`}>
          {documentoCarregado ? (
            <div className={styles.conteudo__corpo}>
              <div className={styles.area_documento}>
                <IoDocumentOutline />
              </div>
              <div className={styles.area_informativo}>
                <span>{formatarNomeArquivo(mensagem.texto)}</span>
                <IoMdDownload
                  className={styles.download}
                  onClick={baixarArquivo}
                />
              </div>
            </div>
          ) : (
            <div className={styles.sem_documento}>
              <Loading />
            </div>
          )}

          <div className={styles.rodape}>
            <span>{horaFormatada}</span>
            {enviadoBot && (
              <>
                {mensagem.status === "delivered" ||
                mensagem.status === "read" ? (
                  <LuCheckCheck />
                ) : (
                  <LuCheck />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
