import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Chat from "./pages/Chat";
import { UsuarioProvider } from "./context/UsuarioContext";
import { SocketProvider } from "./context/SocketContext";
import { MensagemProvider } from "./context/MensagensContext";
import { ProtocoloProvider } from "./context/ProtocoloContext";
import PainelControle from "./pages/PainelControle";
import AlterarSenha from "./components/Login/AlterarSenha";
import { DepartamentoProvider } from "./context/DepartamentoContext";
import { DashboardProvider } from "./context/DashboardContext";
import { OcorrenciaProvider } from "./context/OcorrenciaContext";

function Router() {
  const logado = sessionStorage.getItem("token");

  return (
    <UsuarioProvider>
      <DepartamentoProvider>
        <ProtocoloProvider>
          <MensagemProvider>
            <OcorrenciaProvider>
              <DashboardProvider>
                <SocketProvider>
                  <Routes>
                    <Route path="/" element={<Login />} />
                    <Route
                      path="/chat"
                      element={logado ? <Chat /> : <Navigate to="/" />}
                    />
                    <Route
                      path="/painelcontrole"
                      element={
                        logado ? <PainelControle /> : <Navigate to="/" />
                      }
                    />
                    <Route
                      path="/alterarSenha/:token"
                      element={<AlterarSenha />}
                    />
                  </Routes>
                </SocketProvider>
              </DashboardProvider>
            </OcorrenciaProvider>
          </MensagemProvider>
        </ProtocoloProvider>
      </DepartamentoProvider>
    </UsuarioProvider>
  );
}

export default Router;
