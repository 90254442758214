import styles from "./Select.module.css";

interface SelectProps {
  required?: boolean;
  label?: string;
  value: string;
  opcoes: string[];
  aoAlterado: (valor: string) => void;
  limitaLargura?: boolean;
}

export default function Select({
  required = false,
  label = "",
  value,
  opcoes,
  aoAlterado,
  limitaLargura = false,
}: SelectProps) {
  return (
    <>
      <label className={styles.label}>{label}</label>
      <select
        className={`${styles.select} ${
          limitaLargura ? styles.limitaLargura : ""
        }`}
        required={required}
        value={value}
        onChange={(e) => aoAlterado(e.target.value)}
      >
        {opcoes.map((opcao: string) => {
          return <option key={opcao}>{opcao}</option>;
        })}
      </select>
    </>
  );
}
