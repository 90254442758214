import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import styles from "./Emoji.module.css";

interface EmojiPickerProps {
  setMensagem: React.Dispatch<React.SetStateAction<string>>;
}

export default function Emoji({ setMensagem }: EmojiPickerProps) {
  const onEmojiClick = (emojiData: EmojiClickData) => {
    const emoji = emojiData.emoji; // Obter o emoji da seleção
    setMensagem((prevMensagem) => prevMensagem + emoji);
  };

  return (
    <div className={styles.container}>
      <EmojiPicker onEmojiClick={onEmojiClick} />
    </div>
  );
}
