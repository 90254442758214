import { Select } from "antd";
import { ConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";

interface props {
  value: string;
  aoAlterado: (texto: string) => void;
  opcoes: string[];
  placeholder?: string;
}

export default function SelectInput({
  value,
  aoAlterado,
  opcoes,
  placeholder,
}: props) {
  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const options = opcoes.map((opcao) => ({
    label: opcao,
    value: opcao,
  }));

  return (
    <ConfigProvider locale={ptBR}>
      <Select
        value={value}
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={aoAlterado}
        onSearch={aoAlterado}
        filterOption={filterOption}
        options={options}
      />
    </ConfigProvider>
  );
}
