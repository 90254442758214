import { ChangeEvent, useRef, useState } from "react";
import { MdCloudUpload } from "react-icons/md";
import styles from "./PlanilhaBot.module.css";
import api from "../../../../utils/api";
import Botao from "../../../Shared/Botao";
import CampoInput from "../../../Shared/CampoInput";
import moment from "moment";
import { useUsuario } from "../../../../context/UsuarioContext";

export default function PlanilhaBot() {
  const { usuario } = useUsuario();
  const [arquivo, setArquivo] = useState<File | null>(null);
  const [dataAgendamento, setDataAgendamento] = useState(
    moment().add(1, "days").format("yyyy-MM-DD")
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const formatarNomeArquivo = (nome: string) => {
    const nomeArquivoFormatado =
      nome.length > 20 ? nome.substring(0, 20) + "..." : nome;

    return nomeArquivoFormatado;
  };

  const uploadArquivo = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setArquivo(e.target.files[0]);
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Limpa o valor do input de arquivo
      }
    }
  };

  const enviaArquivo = async () => {
    if (!arquivo) {
      return;
    }
    if (usuario) {
      const dados = new FormData();
      dados.append("arquivo", arquivo);
      dados.append(
        "dataAgendamento",
        moment(dataAgendamento).format("DD/MM/yyyy")
      );
      dados.append("email", usuario?.email);

      try {
        const resposta = await api.post(`/prerota/planilha`, dados, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const dadosResposta = resposta.data;
        setArquivo(null);
        alert(dadosResposta.message);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error);
        setArquivo(null);
        alert(error.response.data.error);
      }
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.titulo}>
        <h3>Criar Planilha Bot</h3>
      </div>
      <div className={styles.conteudo}>
        <CampoInput
          required={true}
          type="date"
          value={dataAgendamento}
          aoAlterado={(e) => setDataAgendamento(e)}
        >
          Data para agendamento
        </CampoInput>
        <div className={styles.upload}>
          <label className={styles.uploadLabel}>
            <span>Upload do Arquivo</span>
            <MdCloudUpload />
            <input
              type="file"
              ref={fileInputRef}
              onChange={uploadArquivo}
              accept=".xlsx"
              className={styles.inputFile}
              hidden
            />
          </label>
          {arquivo ? <p>{formatarNomeArquivo(arquivo.name)}</p> : ""}
        </div>

        <Botao cor="primaria" onClick={enviaArquivo}>
          Enviar
        </Botao>
      </div>
    </div>
  );
}
